/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

html,
body {
  overflow-x: hidden;
}

.row:before,
.row:after {
  display: none !important;
}

body {
  background: #fff;
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.4;
  font-weight: 400;
  padding-top: 70px;
}

a {
  color: #e93a1e;
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  color: #123d6a;
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0 0 30px 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
  margin: 0 0 20px 0;
  padding: 0;
}

/*@media screen and (-webkit-min-device-pixel-ratio:0) {
::i-block-chrome, .align-self-center {
display: table-cell;
vertical-align: middle;
}
    ::i-block-chrome, .d-flex {
display: flex!important;
display: table !important;
}
}*/

/* Back to top button */

.cal-month-view .cal-day-number {
  opacity: 10;
}

.cal-month-view .cal-day-cell.cal-out-month .cal-day-number {
  opacity: 0.4;
}

.cal-month-view .cal-day-cell.cal-today {
  background-color: #e73a1e;
  color: #fff;
}

.cal-month-view .cal-cell-row .cal-cell:hover,
.cal-month-view .cal-cell.cal-has-events.cal-open {
  background-color: #123d6a;
  color: #fff !important;
}

.four-boxes#programmes .box .icon img {
  max-width: 45px;
}

.program-img-bx {
  height: 300px;
  display: flex;
  overflow: hidden;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.home_programmes {
  background: #fff;
}

.page_search {
  background: #fff6ef;
}

.page_search .form-control {
  min-height: 45px;
  border-color: #c5c5c5;
}

.page_search .ngx-datepicker-input {
  border: none !important;
  min-height: auto;
}

.program-img-bx img {
  width: 100%;
  height: 345px;
  object-fit: contain;
}

@media (max-width: 922px) {
  .program-img-bx img {
    padding: 20px;
  }
}

@media (min-width: 1500px) {
  .program-img-bx img {
    padding: 20px;
  }
}

@media (min-width: 1600px) {
  .program-img-bx img {
    padding: 20px;
  }
}

@media (min-width: 1700px) {
  .program-img-bx img {
    padding: 20px;
  }
}

.program-img-bx a {
  display: inline-block;
  width: 100%;
}

section.program_list .fa.fa-search {
  font-size: 20px;
}

.gallery_detail_caption h3 {
  margin-bottom: 0px;
  color: #123d6a;
  font-weight: 400;
  font-size: 20px;
  margin-top: 10px;
}

.gallery_detail_caption {
  margin-bottom: 20px;
}

.back-to-top {
  position: fixed;
  display: none;
  background: #ff7900;
  color: #fff;
  padding: 6px 12px 9px 12px;
  font-size: 16px;
  border-radius: 100%;
  right: 15px;
  bottom: 15px;
  transition: background 0.5s;
  z-index: 9;
}

.back-to-top:before {
  content: none !important;
}

.back-to-top:focus {
  background: #128807;
  color: #fff;
  outline: none;
}

.back-to-top:hover {
  background: #123d6a;
  color: #fff;
}

/*--------------------------------------------------------------
# Top Bar
--------------------------------------------------------------*/

#topbar {
  background: url(../images/top-black.png);
  background-repeat: repeat;
  padding: 2px 5%;
  font-size: 14px;
  color: #fff;
  z-index: 99;
  top: 0px;
  width: 100%;
}

.contact-info {
  float: right;
  padding-top: 3px;
  padding-bottom: 3px;
}

#topbar .contact-info a {
  line-height: 1;
  color: #fff;
}

#topbar .contact-info i {
  color: #fff;
  padding: 4px;
}

#topbar .contact-info a {
  padding-left: 8px;
  margin-left: 8px;
  border-left: 1px solid #fff;
  cursor: pointer;
}

#topbar .social-links a {
  color: #fff;
  padding: 4px 12px;
  display: inline-block;
  line-height: 1px;
  border-left: 1px solid #fff;
}

#topbar .social-links a:hover {
  color: #000;
}

#topbar .social-links a:first-child {
  border-left: 0;
}

/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/

.pos-rel {
  position: relative;
}

#header {
  height: 84px;
  transition: all 0.5s;
  z-index: 99;
  background: #fff;
  position: relative;
  box-shadow: 0px 6px 9px 0px rgba(0, 0, 0, 0.06);
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.is-sticky #header {
  border-bottom: solid 2px #a0a0a0;
}

#header:before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 130px;
  height: 84px;
  z-index: 1;
  background: url(../img/right-header-bg.png) no-repeat left top;
  background-size: 100% 100%;
  opacity: 0.4;
}

#header:after {
  position: absolute;
  content: "";
  right: 0;
  top: 0;
  width: 130px;
  height: 84px;
  z-index: 1;
  background: url(../img/left-header-bg.png) no-repeat left top;
  background-size: 100% 100%;
  opacity: 0.4;
}

#header .container {
  padding: 15px 0;
  height: 100%;
}

.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.8);
  padding-left: 15px;
  padding-right: 15px;
}

#header.sticky {
  position: fixed;
  top: 30px;
  z-index: 99;
  width: 100%;
}

/* .sticky .cm-profile {
  top: 1px;
  width: 74px;
  right: 20px;
  -webkit-transition: all .25s ease-in-out;
  -moz-transition: all .25s ease-in-out;
  -ms-transition: all .25s ease-in-out;
  -o-transition: all .25s ease-in-out;
  transition: all .25s ease-in-out;
} */

.sticky #logo {
  top: -1px;
  left: 0;
  width: 297px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.sticky #nav-menu-container {
  margin-top: 5px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.sticky .nav-menu a,
.sticky .navbar-nav a {
  padding: 12px 11px !important;
  font-weight: 400;
  font-size: 85%;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  color: #000;
}

a.budget-icon img {
  max-width: 120px;
  /* position: absolute; */
  left: 29%;
  top: 5px;
  z-index: 999;
  transform: translateX(-33%);
}

.budget-icon2 img {
  max-width: 120px;
  /* position: absolute; */
  left: 40%;
  z-index: 999;
  top: 25px;
  transform: translateX(-40%);
}

.top-add-news {
  text-align: center;
  margin: 40px 0px 0px 0px;
}

.top-add-news img {
  max-width: 200px;
  margin: 0px 10px;
}

.sticky .sf-arrows .sf-with-ul {
  padding-right: 22px;
}

#header-sticky-wrapper,
.top_border {
  position: relative;
  margin-top: 30px;
}

#header-sticky-wrapper:after,
.top_border:after {
  content: "";
  height: 12px;
  background: #ff7900;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -12px;
  z-index: 9;
}

/*.is-sticky #header {
  padding:10px 0;
  height: 60px;
  -webkit-transition: all .25s ease-in-out;
     -moz-transition: all .25s ease-in-out;
      -ms-transition: all .25s ease-in-out;
       -o-transition: all .25s ease-in-out;
          transition: all .25s ease-in-out;
}*/

.is-sticky .cm-profile {
  top: 3px;
  width: 74px;
  right: 20px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.is-sticky #logo {
  top: 0;
  left: 0;
  width: 290px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.is-sticky #nav-menu-container {
  margin-top: 5px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.is-sticky .nav-menu a,
.is-sticky .navbar-nav a {
  padding: 6px 5px !important;
  font-weight: 400;
  font-size: 13px;
  letter-spacing: 0.5px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  color: #000;
}

.is-sticky .sf-arrows .sf-with-ul {
  padding-right: 22px;
}

#header-sticky-wrapper {
  position: relative;
}

#header-sticky-wrapper:after {
  content: "";
  height: 12px;
  background: #ff7900;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: -12px;
  z-index: 9;
}

#logo {
  position: absolute;
  z-index: 9;
  top: -30px;
  left: -20px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
}

.cm-profile {
  position: absolute;
  top: 5px;
  width: auto;
  right: 0px;
  -webkit-transition: all 0.25s ease-in-out;
  -moz-transition: all 0.25s ease-in-out;
  -ms-transition: all 0.25s ease-in-out;
  -o-transition: all 0.25s ease-in-out;
  transition: all 0.25s ease-in-out;
  z-index: 99999;
}

.cm-profile img {
  max-width: 60px;
  margin: auto;
}

.cm-profile-header-info {
  background: #fff;
  padding: 4px;
  border-radius: 0px;
  font-size: 95%;
}

#about .cmo-img {
  max-width: 180px;
}

#header #logo h1 {
  font-size: 42px;
  margin: 0;
  padding: 0;
  line-height: 1;
  font-weight: 700;
}

#header #logo h1 a {
  color: #123d6a;
  line-height: 1;
  display: inline-block;
}

#header #logo h1 a span {
  color: #128807;
}

#header #logo img {
  padding: 0;
  margin: 0;
}

/*--------------------------------------------------------------
# Intro Page Intro Section
--------------------------------------------------------------*/

.intro-page #logo {
  top: 0;
  background: rgba(255, 255, 255, 0.7);
  padding: 10px 15px;
  border-radius: 0 0 5px 0;
  width: 300px;
}

.intro-page #intro,
.intro-page #intro #intro-carousel .item {
  height: 100vh;
}

.intro-page #intro #intro-carousel {
  border-bottom: 0;
}

.intro-page .carousel-item img {
  height: 100vh;
}

.intro-enter {
  position: absolute;
  z-index: 99;
  bottom: 0;
  left: 0;
  padding: 10px;
  background: rgba(0, 119, 222, 0.5);
  width: 100%;
}

.ie-containt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.ie-containt a {
  color: #fff;
}

.ie-containt span {
  margin-left: 15px;
}

/*--------------------------------------------------------------
# Intro Section
--------------------------------------------------------------*/

.top_slider .carousel-item img {
  height: 70dvh;
  /* height: 100%; */
}

#intro {
  width: 100%;
  height: 65vh;
  position: relative;
  background-size: cover;
}

#intro .intro-content {
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  z-index: 10;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}

#intro .intro-content h2 {
  color: #123d6a;
  margin-bottom: 30px;
  font-size: 64px;
  font-weight: 700;
}

#intro .intro-content h2 span {
  color: #128807;
  text-decoration: underline;
}

#intro .intro-content .btn-get-started,
#intro .intro-content .btn-projects {
  font-family: "Raleway", sans-serif;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 1px;
  display: inline-block;
  padding: 10px 32px;
  border-radius: 2px;
  transition: 0.5s;
  margin: 10px;
  color: #fff;
}

#intro .intro-content .btn-get-started {
  background: #123d6a;
  border: 2px solid #123d6a;
}

#intro .intro-content .btn-get-started:hover {
  background: none;
  color: #123d6a;
}

#intro .intro-content .btn-projects {
  background: #128807;
  border: 2px solid #128807;
}

#intro .intro-content .btn-projects:hover {
  background: none;
  color: #128807;
}

#intro #intro-carousel {
  z-index: 8;
  border-bottom: 5px solid #128807;
}

#intro #intro-carousel::before {
  content: "";
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 7;
}

#intro #intro-carousel .item {
  width: 100%;
  height: 65vh;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  transition-property: opacity;
}

#intro #intro-carousel .item img {
  height: 100%;
}

/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/

/* Nav Menu Essentials */

.dropdown:hover>.dropdown-menu {
  display: block;
}

.nav-menu,
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}

.nav-menu ul {
  position: absolute;
  display: none;
  top: 100%;
  left: 0;
  z-index: 99;
}

.navbar-nav li:hover>ul.dropdown-menu {
  display: block;
}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  margin-top: -6px;
  margin-left: 10px;
}

/* rotate caret on hover */
.dropdown-menu>li>a:hover:after {
  text-decoration: underline;
  transform: rotate(-90deg);
}

.navbar-light .navbar-nav li {
  position: relative;
}

.navbar-light .navbar-nav .nav-link span {
  position: absolute;
  right: 0;
}

.megamenu ul {
  position: relative;
  top: 0;
  display: block;
  box-shadow: none !important;
  padding: 0px !important;
}

.megamenu ul li a {
  padding: 5px !important;
}

.nav-menu li {
  position: relative;
  white-space: nowrap;
}

.nav-menu>li {
  float: left;
}

.nav-menu li:hover>ul,
.nav-menu li.sfHover>ul {
  display: block;
}

.nav-menu ul ul {
  top: 0;
  left: 100%;
}

.nav-menu ul li {
  min-width: 180px;
}

/* Nav Menu Arrows */

.sf-arrows .sf-with-ul {
  padding-right: 22px;
}

.sf-arrows .sf-with-ul:after {
  content: "\f107";
  position: absolute;
  right: 8px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
}

.sf-arrows ul .sf-with-ul:after {
  content: "\f105";
}

/* Nav Meu Container */

#nav-menu-container,
.navbar-nav {
  float: right;
  margin: 0 160px 0 0;
}

/* Nav Meu Styling */

.nav-menu a,
.navbar-nav a {
  padding: 8px 12px;
  text-decoration: none;
  display: inline-block;
  color: #555;
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  font-size: 100%;
  letter-spacing: 1px;
  outline: none;
}

a.dropdown-item {
  font-size: 14px;
}

.nav-menu li:hover>a,
.navbar-nav li:hover>a,
.navbar-nav a:hover,
.nav-menu .menu-active>a {
  color: #fff;
  background: transparent;
  border-radius: 5px;
}

/* .nav-menu>li {
  margin-left: 10px;
} */

.nav-menu ul,
.dropdown-menu {
  margin: 0px 0 0 0;
  padding: 10px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  background: #fff;
}

.nav-menu ul li {
  transition: 0.3s;
}

.nav-menu ul li a {
  padding: 6px;
  color: #333;
  transition: 0.3s;
  display: block;
  font-size: 12px;
  text-transform: none;
}

.nav-menu ul li:hover>a,
.navbar-nav li:hover>a {
  color: #000 !important;
}

.nav-menu ul ul {
  margin: 0;
}

/* Mobile Nav Toggle */

#mobile-nav-toggle {
  position: fixed;
  right: 0;
  top: 0;
  z-index: 999;
  margin: 16px 20px 0 0;
  border: 0;
  background: none;
  font-size: 20px;
  display: none;
  line-height: 1.3;
  transition: all 0.4s;
  outline: none;
  background: #123d6a;
  cursor: pointer;
  border-radius: 5px;
  padding-top: 0px;
}

#mobile-nav-toggle i {
  color: #fff;
}

/* Mobile Nav Styling */

#mobile-nav {
  position: fixed;
  top: 0;
  padding-top: 18px;
  bottom: 0;
  z-index: 998;
  background: rgba(52, 59, 64, 0.9);
  left: -260px;
  width: 260px;
  overflow-y: auto;
  transition: 0.4s;
}

#mobile-nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

#mobile-nav ul li {
  position: relative;
}

#mobile-nav ul li a {
  color: #fff;
  font-size: 14px;
  overflow: hidden;
  padding: 10px 22px 10px 15px;
  position: relative;
  text-decoration: none;
  width: 100%;
  display: block;
  outline: none;
}

#mobile-nav ul li a:hover {
  color: #fff;
}

#mobile-nav ul li li {
  padding-left: 30px;
}

#mobile-nav ul .menu-has-children i {
  position: absolute;
  right: 0;
  z-index: 99;
  padding: 15px;
  cursor: pointer;
  color: #fff;
}

#mobile-nav ul .menu-has-children i.fa-chevron-up {
  color: #123d6a;
}

#mobile-nav ul .menu-item-active {
  color: #123d6a;
  background: #fff;
}

#mobile-body-overly {
  width: 100%;
  height: 100%;
  z-index: 997;
  top: 0;
  left: 0;
  position: fixed;
  background: rgba(52, 59, 64, 0.9);
  display: none;
}

/* Mobile Nav body classes */

body.mobile-nav-active {
  overflow: hidden;
}

body.mobile-nav-active #mobile-nav {
  left: 0;
}

body.mobile-nav-active #mobile-nav-toggle {
  color: #fff;
}

/*--------------------------------------------------------------
# Sections
--------------------------------------------------------------*/

/* Sections Header
--------------------------------*/

.section-header {
  margin-bottom: 30px;
}

.section-header h2 {
  font-size: 30px;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  position: relative;
  padding-bottom: 15px;
}

.section-header h2::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 3px;
  /* background: url(green-bar.555d1d4….jpg) left top no-repeat; */
  bottom: 0;
  left: 0;
  border-bottom: solid 2px #ff7900;
  max-width: 60px;
  display: none;
}

.section-header p {
  padding: 0;
  margin: 0;
}

/* About Section
--------------------------------*/

#about {
  padding: 25px 0px;
}

#about .about-img {
  overflow: hidden;
}

#about .about-img img {
  margin-left: -15px;
  max-width: 100%;
}

#about .content h2 {
  color: #123d6a;
  font-weight: 700;
  font-size: 24px;
  font-family: "Raleway", sans-serif;
}

#about .content h3 {
  color: #555;
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 10px;
  font-style: italic;
}

#about .content p {
  line-height: 26px;
}

#about .content p:last-child {
  margin-bottom: 0;
}

#about .content i {
  font-size: 20px;
  padding-right: 4px;
  color: #128807;
}

#about .content ul {
  list-style: none;
  padding: 0;
}

#about .content ul li {
  padding-bottom: 10px;
}

/*Four Box Section
--------------------------------*/

.four-boxes#programmes {
  padding: 0;
  /* margin-top: -60px; */
  position: relative;
  z-index: 9;
  background: #123d6a;
}

.four-boxes#programmes .col-lg-3.col-6 {
  flex: 1;
}

.four-boxes#programmes .box {
  padding: 10px;
  text-align: center;
  background: #123d6a;
  border-radius: 5px;
  margin-bottom: 0px;
  position: relative;
  border: none;
  height: auto !important;
}

.four-boxes#programmes .box:after {
  border: solid 1px #47688b;
  min-height: 54px;
  position: absolute;
  right: -21px;
  top: 16px;
  content: "";
}

.four-boxes#programmes .col-lg-3:nth-last-child(1) .box:after {
  display: none;
}

/*    .four-boxes#programmes .box:after {
      content: '';
      background: url(../img/rajasthan-map.png) no-repeat right top;
      height: 100%;
      width: 120px;
      top: 0;
      right: 0;
      position: absolute;
      background-size: 100% auto;
      opacity: .2;
	  display:none;
    }*/

.four-boxes#programmes .box .icon {
  float: none;
  text-align: center;
  margin-bottom: 3px;
  margin-right: 0px;
}

.four-boxes#programmes .box h4 {
  margin-left: 0;
  font-size: 105%;
  margin-bottom: 0px;
  font-weight: 400;
}

.four-boxes#programmes .box i {
  font-size: 9px;
  position: relative;
  top: 0px;
}

.four-boxes#programmes .box h4 a {
  color: #fff;
}

.four-boxes#programmes .box p {
  margin-left: 0;
  line-height: 1.5;
  font-size: 13px;
  color: #fff;
}

.four-boxes#programmes .box .icon i {
  color: #444;
  font-size: 36px;
  transition: 0.5s;
  line-height: 0;
  margin-top: 0;
  line-height: 1;
}

.four-boxes#programmes .box .icon i:before {
  background: #fff;
}

/* Services Section
--------------------------------*/

.home_programmes {
  padding: 40px 0px 0px 0px;
}

#programmes .pbox {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

#programmes .box {
  padding: 2px;
  margin-bottom: 20px;
  box-shadow: 10px 10px 15px rgba(73, 78, 92, 0.1);
  background: #fff;
  border: solid 1px #f1f1f1;
  transition: 0.4s;
  border-radius: 15px;
}

/*    #programmes .box:hover {
      box-shadow: 0px 0px 30px rgba(73, 78, 92, 0.15);
      transform: translateY(-10px);
      -webkit-transform: translateY(-10px);
      -moz-transform: translateY(-10px);
    }
*/

#programmes .box .icon {
  margin-right: 15px;
}

.message_cm #programmes .box .icon {
  width: 12%;
  height: auto;
}

#programmes .pbox .icon {
  width: 50%;
  text-align: center;
  height: 140px;
  overflow: hidden;
  align-items: center;
  display: flex;
  align-content: center;
  justify-content: center;
}

#programmes .pbox .icon img {
  max-height: 140px;
}

#programmes .box .icon i {
  color: #444;
  font-size: 64px;
  transition: 0.5s;
  line-height: 0;
  margin-top: 34px;
}

#programmes .box .icon i:before {
  background: #123d6a;
  background: linear-gradient(45deg, #128807 0%, #a3ebd5 100%);
  background-clip: border-box;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#programmes .programme-content {
  width: 50%;
  padding-right: 10px;
}

#programmes .programme-content>a {
  font-size: 92%;
  padding-left: 0;
  white-space: nowrap;
  color: #ff7900;
  display: block;
  margin-top: 3px;
}

#programmes .box h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 105%;
  line-height: 1.4;
}

#programmes .box span i {
  margin-right: 8px;
}

#programmes .box .date i {
  margin-right: 2px;
}

#programmes .box h4 a {
  color: #123d6a;
}

#programmes .box p {
  font-size: 13px;
  margin-bottom: 0;
  line-height: 20px;
}

#programmes .box:first-child span {
  padding-left: 0;
}

#programmes .box span {
  font-size: 90%;
  margin-bottom: 0;
  line-height: 20px;
  padding-right: 5px;
  padding-left: 5px;
}

#programmes .box .date {
  font-size: 13px;
}

/* Clients Section
--------------------------------*/

#clients {
  padding: 30px 0;
}

#clients img {
  max-width: 100%;
  opacity: 0.5;
  transition: 0.3s;
  padding: 15px 0;
}

#clients img:hover {
  opacity: 1;
}

#clients .owl-nav,
#clients .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#clients .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#clients .owl-dot.active {
  background-color: #128807;
}

/* Our Portfolio Section
--------------------------------*/

#portfolio {
  background: #fff;
  padding: 30px 0;
}

#portfolio .portfolio-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: all ease-in-out 0.4s;
}

#portfolio .portfolio-item {
  overflow: hidden;
  position: relative;
  padding: 0;
  vertical-align: middle;
  text-align: center;
}

#portfolio .portfolio-item h2 {
  color: #ffffff;
  font-size: 24px;
  margin: 0;
  text-transform: capitalize;
  font-weight: 700;
}

#portfolio .portfolio-item img {
  transition: all ease-in-out 0.4s;
  width: 100%;
}

#portfolio .portfolio-item:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

#portfolio .portfolio-item:hover .portfolio-overlay {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
}

#portfolio .portfolio-info {
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/* Testimonials Section
--------------------------------*/

#testimonials {
  padding: 30px 0;
}

#testimonials .testimonial-item {
  box-sizing: content-box;
  padding: 0px;
  margin: 0;
  text-align: left;
  box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.08);
  background: #123d6a;
  border: 1px solid #123d6a;
  border-radius: 10px 0 10px 0;
  overflow: hidden;
}

#testimonials .testimonial-item:hover {
  background: #f8f8f8;
}

#testimonials .testimonial-item:hover a,
#testimonials .testimonial-item:hover span {
  color: #000;
}

#testimonials .testimonial-item .date {
  background: #007adc;
  text-align: center;
  padding: 17px;
  margin-right: 14px;
  font-size: 20px;
  color: #fff;
}

#testimonials .testimonial-item .detail.align-self-center {
  padding: 10px 0px;
}

#testimonials .testimonial-item span {
  color: #ffe4d8;
  margin-top: 6px;
  display: inline-block;
  font-size: 13px;
  margin-right: 10px;
}

#testimonials .testimonial-item .testimonial-img {
  width: 90px;
  border-radius: 50%;
  border: 4px solid #fff;
  margin: 0 auto;
}

#testimonials .testimonial-item h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}

#testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0;
}

#testimonials .testimonial-item .quote-sign-left {
  margin-top: -15px;
  padding-right: 10px;
  display: inline-block;
  width: 37px;
}

#testimonials .testimonial-item .quote-sign-right {
  margin-bottom: -15px;
  padding-left: 10px;
  display: inline-block;
  max-width: 100%;
  width: 37px;
}

#testimonials .testimonial-item p,
#testimonials .testimonial-item a {
  font-style: italic;
  margin: 0 auto;
  color: #fff;
  font-size: 18px;
  display: block;
}

#testimonials .owl-nav,
#testimonials .owl-dots {
  margin-top: 5px;
  text-align: center;
}

#testimonials .owl-dot {
  display: inline-block;
  margin: 0 5px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #ddd;
}

#testimonials .owl-dot.active {
  background-color: #128807;
}

/* Call To Action Section
--------------------------------*/

#call-to-action {
  background: url("../img/SecretariatPic.jpg") no-repeat top center;
  background-attachment: fixed;
  background-size: cover;
  padding: 40px 0;
  position: relative;
}

.blue-shadow {
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(18, 61, 106, 0.9);
  width: 100%;
  height: 100%;
}

.right-to-info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.right-to-info-img {
  margin-right: 15px;
}

.right-to-info img {
  width: 70px;
}

#call-to-action .cta-title {
  color: #fff;
  font-size: 28px;
  font-weight: 700;
  margin-bottom: 15px;
}

#call-to-action .cta-text {
  color: #fff;
  margin-bottom: 15px;
}

#call-to-action .cta-btn {
  font-weight: 700;
  font-size: 15px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 8px 26px;
  border-radius: 3px;
  transition: 0.5s;
  margin: 10px;
  border: 2px solid #ff7900;
  color: #fff;
  white-space: nowrap;
  background: #ff7900;
}

#call-to-action .cta-btn:hover {
  background: #128807;
  border: 3px solid #128807;
}

/* Our Team Section
--------------------------------*/

#team {
  background: #fff;
  padding: 30px 0 0 0;
}

#team .member {
  text-align: center;
  margin-bottom: 20px;
}

#team .member .pic {
  overflow: hidden;
  text-align: center;
}

#team .member .pic img {
  max-width: 100%;
}

#team .member .details {
  background: #128807;
  color: #fff;
  padding: 15px;
  border-radius: 0 0 3px 3px;
}

#team .member h4 {
  font-weight: 700;
  margin-bottom: 2px;
  font-size: 18px;
}

#team .member span {
  font-style: italic;
  display: block;
  font-size: 13px;
}

#team .member .social {
  margin-top: 5px;
}

#team .member .social a {
  color: #fff;
}

#team .member .social i {
  font-size: 16px;
  margin: 0 2px;
}

/* Contact Section
--------------------------------*/

#contact {
  padding: 30px 0;
}

#contact .contact-info {
  text-align: center;
  margin-bottom: -77px;
  z-index: 1;
  position: relative;
  background: #fff;
  border-radius: 10px;
}

#contact .contact-info i {
  font-size: 28px;
  display: inline-block;
  margin-bottom: 10px;
  color: #fff;
  background: #2868a8;
  min-width: 50px;
  border-radius: 100%;
  min-height: 50px;
  display: inline-block;
  line-height: 50px;
  box-shadow: 0px 0px 43px 0px rgba(73, 78, 92, 0.2);
}

#contact .contact-info address,
#contact .contact-info p {
  margin-bottom: 0;
  color: #000;
  font-size: 90%;
}

#contact .contact-info h3 {
  font-size: 14px;
  margin-bottom: 7px;
  font-weight: bold;
  text-transform: uppercase;
  color: #555;
  margin-top: 7px;
}

ul.footer_social {
  margin: 0px;
  padding: 0px;
}

ul.footer_social li {
  display: inline-block;
  list-style: none;
  margin-bottom: 0px !important;
}

ul.footer_social li a:hover {
  color: #ff7900;
}

ul.footer_social li a {
  /* background: transparent; */
  padding: 0px 8px;
  font-size: 28px;
  color: #fff;
}

ul.footer_social li a:before {
  display: none;
}

#contact .contact-info a {
  color: #000;
}

#contact .contact-info a:hover {
  color: #128807;
}

#contact .contact-address,
#contact .contact-phone,
#contact .contact-email {
  margin-bottom: 30px;
}

#contact #google-map {
  height: 290px;
  margin-bottom: 20px;
}

#contact .form #sendmessage {
  color: #128807;
  border: 1px solid #128807;
  display: none;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #errormessage {
  color: red;
  display: none;
  border: 1px solid red;
  text-align: center;
  padding: 15px;
  font-weight: 600;
  margin-bottom: 15px;
}

#contact .form #sendmessage.show,
#contact .form #errormessage.show,
#contact .form .show {
  display: block;
}

#contact .form .validation {
  color: red;
  display: none;
  margin: 0 0 20px;
  font-weight: 400;
  font-size: 13px;
}

#contact .form input,
#contact .form textarea {
  padding: 10px 14px;
  border-radius: 0;
  box-shadow: none;
  font-size: 15px;
}

#contact .form button[type="submit"] {
  background: #128807;
  border: 0;
  border-radius: 3px;
  padding: 10px 30px;
  color: #fff;
  transition: 0.4s;
  cursor: pointer;
}

#contact .form button[type="submit"]:hover {
  background: #2dc899;
}

.btn-primary {
  color: #fff;
  background-color: #e83a1e;
  border-color: #e83a1e;
}

.btn-primary:hover {
  color: #fff;
  background-color: #d61d00;
  border-color: #d61d00;
}

.calendar_page .table-responsive {
  overflow: hidden;
}

.calendar_page h3 {
  margin-bottom: 0px;
  margin-top: 8px;
  font-size: 24px;
}

.cal-month-view .cal-header .cal-cell {
  background: #123d6a;
  color: #fff;
}

nav.breadcrumb_bg {
  background: #e9ecef;
}

.breadcrumb {
  padding-left: 60px;
}

/*--------------------------------------------------------------
# Footer Links
--------------------------------------------------------------*/

.footer-links {
  color: #000;
  position: relative;
  background: #e1e1e1;
  /*background-attachment: fixed;*/
  padding: 25px 15px;
  background-position: center center;
  text-align: center;
}

/* .footer-links:before {
  background: rgb(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0px;
  right: 0px;
  content: "";
  z-index: 0;
} */

/* .blck-shadow {
    position: absolute;
    left: 0;
    top: 0;
    background: rgb(29, 51, 75, 0.9);
    width: 100%;
    height: 100%;
} */

.footer-links h4 {
  font-size: 20px;
  margin: 10px 0px 20px 0px;
  color: #000;
  font-weight: 500;
}

.footer-links:after {
  content: "";
  background: url(../img/rajasthan-map.png) no-repeat right top;
  height: 100%;
  width: 300px;
  top: 0;
  right: 0;
  position: absolute;
  background-size: 100% auto;
  opacity: 0.2;
  display: none;
}

.footer-links ul {
  list-style: none;
  margin: 5px 0px;
  padding: 0;
  text-align: center;
}

.footer-links ul li {
  /* font-size: 13px; */
  margin-bottom: 0px;
  padding: 0px;
  display: inline-block;
}

.footer-links a {
  color: #000;
  padding: 0px 10px;
}

.footer-links a:hover {
  text-decoration: underline;
}

/* .footer-links a:before {
  content: '\f101';
  font-family: FontAwesome;
  margin-right: 5px;
} */

.footer-links .gandhi150 {
  border-radius: 10px;
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

#footer {
  background: url(../images/top-black.png);
  background-repeat: repeat;
  padding: 5px 5% 9px;
  font-size: 14px;
  color: #fff;
  z-index: 99;
  top: 0px;
  width: 100%;
}

#footer .copyright {
  text-align: center;
  padding-top: 6px;
}

.footer_visitor {
  padding-top: 6px;
}

#footer .credits {
  text-align: center;
  font-size: 12px;
  color: #fff;
}

#footer .credits a {
  color: #fff;
}

.gray-btn {
  background: #eee;
  border: none;
  padding: 10px 2%;
  color: #555;
  border-radius: 8px;
  box-shadow: 0px 0px 39px 0px rgba(73, 78, 92, 0.2);
  border-radius: 39px;
  display: inline-block;
}

.gray-btn:hover,
.gray-btn:focus {
  background: #123d6a;
  color: #fff;
}

.gallery {
  display: inline-block;
  margin-top: 20px;
}

/*    Page Breadcrumbs Style
/*------------------------------------------*/

ol.breadcrumb li a {
  color: #fff;
}

.breadcrumbs-page-img {
  background: url(../img/breadcrumbs_bg.jpg) !important;
  background-repeat: no-repeat !important;
  background-size: 100% !important;
}

.bg-scroll.pos-rel {
  background-repeat: no-repeat;
  padding: 3rem 0;
  background-position: center top !important;
  margin-top: 0px;
  background: #eaf4ff;
}

.bg-scroll.pos-rel .breadcrumb {
  background: transparent;
  color: #fff;
  padding: 0;
  font-size: 0.875rem;
  font-weight: 600;
}

.breadcrumb {
  border-radius: 0px;
  background-color: #123d6a;
}

.bg-scroll.pos-rel .breadcrumb a,
.bg-scroll.pos-rel .breadcrumb-item+.breadcrumb-item::before,
.breadcrumb-item.active {
  color: #ff7900;
  font-weight: 600;
}

.bg-scroll.pos-rel .breadcrumb-item+.breadcrumb-item::before {
  top: 1px;
  position: relative;
}

.bg-scroll.pos-rel h1 {
  color: #123d6a;
  text-transform: uppercase;
  font-size: 20px;
  font-weight: 700;
  margin: 0;
  /* text-shadow: 2px 2px 4px #000; */
}

.breadcrumbs-description {
  padding: 0.75rem 1.5rem;
  color: #fff;
  border-left: 2px solid #ff9933;
  max-width: 50%;
  margin-top: 15px;
}

.bg-scroll {
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}

.font-16 {
  font-size: 16px;
}

section.cmrelief,
section.program_list,
section.program_list_detail,
section.gallery_page {
  padding: 25px 0px;
}

.program_list_box .card-body {
  background: #f1f1f1;
}

.gallery_page .gallery_caption .date {
  font-size: 80%;
}

/*.gallery_page .card {
  text-align: center;
}

  .gallery_page .card img {
    height: 420px;
   width:650px;
  }*/

section.program_list .card {
  border: none;
  margin: 0px 0px 30px 0px;
  box-shadow: 0px 0px 20px 0px rgb(0 0 0 / 15%);
  /* border: solid 1px #ccc; */
  border-radius: 15px;
  overflow: hidden;
}

.av-caption-date.bg-theme {
  font-size: 108%;
  padding: 7px;
  text-align: right;
}

.new-card-info {
  padding: 15px;
  background: #eef7ff;
}

.new-card-info p {
  margin: 0px !important;
}

.av-caption {
  font-size: 120%;
  margin-bottom: 5px;
  font-weight: 600;
  color: #000;
}

section.program_list .program_list_box .card a.program_btn_more {
  background: #094f82;
  color: #fff;
  padding: 6px 15px;
  border-radius: 5px;
  float: left;
}

section.program_list .program_list_box .card a.program_btn_more:hover {
  color: #ffffff;
  background: #123d6a;
  border: 1px solid #123d6a;
}

.program_list_box .card-body a {
  color: #000;
}

.form-control {
  box-shadow: none;
}

.form-control:focus {
  border-color: #eb3a1e !important;
  box-shadow: none !important;
}

.input-group-append .btn {
  background: #ee3b1f;
  color: #fff;
}

section.program_list .program_list_box .card a:hover {
  background: #fff;
  color: #123d6a;
}

section.program_list .card p {
  margin-bottom: 10px;
  white-space: normal;
}

section.program_list .card .card-title a {
  color: #000;
}

section.program_list .card .card-footer {
  padding: 0.5rem 1.25rem;
  background-color: transparent;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

ul.pagination .page-link {
  color: #000;
}

.program_list_detail .program_detail_left h3 {
  font-size: 170%;
  margin-top: 30px;
  font-weight: 400;
  color: #123d6a;
  margin-bottom: 10px;
}

.program_detail_right .row {
  margin: 0px;
}

.program_list_detail .program_detail_left p {
  margin-bottom: 10px;
}

.program_list_detail .program_detail_left i {
  margin-right: 2px;
}

.detail_icon a i {
  font-size: 20px;
  margin: 15px 0px 20px 0px;
  background: #fff;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #c5c5c5;
}

.program_detail_right .media img {
  max-width: 64px;
  border: solid 1px #f1f1f1;
  border-radius: 4px;
}

.program_detail_right .media .media-body h5 {
  font-size: 15px;
  margin-bottom: 2px;
}

.program_detail_right .media .media-body h5 a {
  color: #000;
}

.program_detail_right .media .media-body p {
  margin-bottom: 0px;
  font-size: 13px;
}

.program_detail_right .media {
  padding: 7px 5px;
  border: 1px solid #f1f1f1;
  margin: 0px 0px 10px 0px;
  border-radius: 5px;
}

.program_detail_right .section-header {
  margin-bottom: 20px;
  display: block;
}

.program_detail_right .section-header h2 {
  font-size: 20px;
  margin: 0px;
  padding: 0px;
}

.help_box {
  background: #f7fff7;
}

.help_box h3 {
  font-size: 18px;
  color: #123d6a;
}

.raj_details p {
  text-align: justify;
}

.btn-theme {
  color: #fff !important;
  font-weight: 700;
  text-transform: uppercase;
  padding: 10px 20px;
  font-size: 14px;
  display: inline-block;
  display: inline-flex;
  outline: none;
  border: none;
  cursor: pointer;
  overflow: hidden;
  z-index: 2;
  align-items: center;
  position: relative;
  cursor: pointer;
  -webkit-box-shadow: 0px 14px 10px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 14px 10px 0px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 14px 10px 0px rgba(0, 0, 0, 0.1);
  -webkit-border-radius: 70px;
  -moz-border-radius: 70px;
  border-radius: 70px;
}

.bg-blue {
  background: #123d6a;
}

.btn-theme:before {
  background-color: rgba(255, 255, 255, 0.15);
  position: absolute;
  top: 0;
  left: 60%;
  right: 60%;
  bottom: 0;
  opacity: 0;
  content: "";
  z-index: -2;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0;
}

.btn-theme:hover::before {
  left: 0;
  right: 0;
  opacity: 1;
  -webkit-transition: 0.5s all ease;
  -o-transition: 0.5s all ease;
  transition: 0.5s all ease;
  box-shadow: none;
}

.btn-theme:hover {
  box-shadow: none;
}

.btn-theme:focus,
.btn-theme:active {
  outline: none;
  box-shadow: none;
}

ul.quick_links {
  margin: 0px;
  padding: 0px 0px 0px 0px;
}

ul.quick_links li {
  border-bottom: solid 1px #f1f1f1;
  padding: 7px 0px;
  list-style: none;
}

ul.quick_links li a {
  color: #464646;
}

/* .program_detail_right .media:last-child {
    border: none;
} */

.black-theme {
  background: #000;
  padding-right: 8px;
  border-left: none !important;
}

.black-theme:hover {
  color: #fff !important;
}

.white-theme {
  background: #fff;
  padding-right: 8px;
  border-left: none !important;
  color: #000 !important;
}

section.program_list.cm-rajasthan {
  padding: 130px 0px 0px 0px;
}

.cm-rajasthan .cm_box {
  margin-bottom: 100px;
  min-height: 260px;
  position: relative;
  box-shadow: 0px 3px 15px 0px rgba(73, 78, 92, 0.1);
}

.cm-rajasthan .cm_box img {
  max-width: 120px;
  margin-top: -60px;
  padding: 5px;
  border-radius: 3px;
  border: solid 1px #f1f1f1;
}

.cm-rajasthan .cm_box h3 {
  font-size: 18px;
  margin: 15px 0px;
  color: #000;
}

.cm-rajasthan .cm_box h4 {
  font-size: 15px;
  margin: 5px 0px;
}

.cm-rajasthan .cm_box ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.cm-rajasthan .cm_box ul li {
  padding: 3px 0px;
  border-bottom: solid 1px #f5f5f5;
  font-size: 13px;
}

.cm-rajasthan .cm_box ul li:last-child {
  border-bottom: none;
}

.cm-rajasthan .cm_box .footer {
  background: #27489e;
  padding: 6px;
  margin-top: 15px;
  position: absolute;
  width: 100%;
  bottom: 0px;
  font-size: 13px;
  color: #fff;
}

.table_blue thead {
  background: #123d6a;
  color: #fff;
}

.megamenu {
  position: static !important;
}

.megamenu .dropdown-menu {
  box-shadow: none;
  background: none;
  border: none;
  width: 100%;
  top: 40px;
}

.megamenu .dropdown-menu .container {
  max-width: 900px;
  box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
  padding: 0px !important;
}

.megamenu .dropdown-menu h6 {
  font-size: 13px;
  color: #ea6426;
  margin: 10px 0px;
}

.profile_box {
  border: solid 1px #f1f1f1;
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.1);
}

.profile_box .name {
  font-size: 24px;
  font-weight: 600;
  color: #ffffff;
  padding: 0px;
}

.profile_box .chief {
  font-size: 14px;
  margin-bottom: 0px;
  color: #fff;
}

.cm_img_name {
  background: #000;
  padding: 6px;
}

.profile_detail h4 {
  font-size: 19px;
  margin-bottom: 11px;
}

.profile_detail p {
  margin-bottom: 10px;
  text-align: justify;
}

.profile_detail .section-header {
  margin: 20px 0px;
}

.rajasthan_page .no-gutters>.col,
.rajasthan_page .no-gutters>[class*="col-"] {
  padding: 1px;
}

table.cabinet_table img {
  /*    max-width: 150px;
    border: solid 1px #ccc;
    padding: 3px;*/
  display: inherit;
}

table.cabinet_table ul {
  margin: 0px;
  padding: 0px 0px 0px 19px;
}

/*Profile card 2*/

.cmrelief .profile-card-2 .card-img-block {
  float: left;
  width: 100%;
  height: 90px;
  overflow: hidden;
  border-bottom: solid 1px #f1f1f1;
  text-align: center;
}

.cmrelief .profile-card-2 .card-img-block img {
  max-width: 315px;
}

.cmrelief .profile-card-2 .card-body {
  position: relative;
}

.cmrelief .profile-card-2 .profile {
  border-radius: 50%;
  position: absolute;
  top: -25px;
  right: 0%;
  max-width: 40px;
  border: 3px solid rgba(255, 255, 255, 1);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  background: #fff;
}

.cmrelief .profile-card-2 strong {
  color: #123d6a;
  font-size: 15px;
}

.cmrelief .profile-card-2 .card-text {
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #000;
}

.rti .profile-card-2 .card-text {
  font-size: 14px;
  color: #0a0a0a;
  line-height: 22px;
}

.gallery_caption {
  background: #f1f1f1;
  padding: 12px;
  width: 100%;
}

.gallery_caption h3 {
  font-size: 100%;
  margin: 0 0 3px;
  color: #123d6a;
  font-weight: 600;
}

.location {
  margin-bottom: 6px;
  font-size: 13px;
}

.table_calendar td {
  border: none;
}

.table_calendar td {
  border: solid 3px #fff;
  height: 100px;
  background: #f5f8ff;
  text-align: center;
  font-size: 34px;
  position: relative;
}

.table_calendar td:nth-of-type(odd) {
  background: #fff9f8;
}

a.event-icon {
  position: absolute;
  right: 6%;
  bottom: 6%;
  font-size: 25px;
}

/*.cmo-message {
    max-width: 1200px;
    margin: auto;
}*/

.msg_text {
  margin-left: 37px;
  font-size: 18px;
}

.msg_text .name {
  color: #123d6a;
  font-size: 20px;
}

.msg_text .name span {
  color: #ff7900;
}

.gray-orange {
  background: #ff7900;
  color: #fff;
  cursor: pointer;
}

.btn-search {
  font-size: 17px;
  line-height: 25px;
}

section.box-content .press_releases ul {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

section.box-content {
  padding: 50px 0px 0px 0px;
  background: linear-gradient(0deg, #ed801d 0%, rgba(255, 255, 255, 0) 100%);
  margin-top: 20px;
}

section.box-content .press_releases_bx {
  box-sizing: content-box;
  padding: 0px;
  margin: 0;
  text-align: left;
  background: transparent;
  overflow: hidden;
  display: flex;
  cursor: pointer;
}

section.box-content .press_releases_bx .press_releases_bx-detail-img img {
  max-width: 65px;
}

section.box-content .press_releases_bx .press_releases_bx-detail {
  margin-left: 15px;
  font-size: 16px;
  font-weight: 600;
  color: #000;
}

section.box-content .press_releases_bx .press_releases_bx-detail small {
  margin-top: 5px;
  display: block;
  color: #000;
}

section.box-content .press_releases_bx .date {
  background: #204871;
  text-align: center;
  padding: 17px;
  margin-right: 14px;
  font-size: 20px;
  color: #fff;
  min-width: 98px;
}

section.box-content .press_releases ul li:hover {
  background: rgba(255, 255, 255, 0.2);
}

section.box-content .press_releases ul li {
  padding: 10px 10px;
  border-radius: 10px;
}

section.box-content .press_releases_bx .detail.align-self-center {
  padding: 10px 0px;
}

section.box-content .press_releases_bx .detail a {
  margin: 0 auto;
  color: #004f8e;
  font-size: 110%;
  display: block;
}

section.box-content .press_releases_bx .detail span {
  color: #2b2b2b;
  margin-top: 6px;
  display: inline-block;
  font-size: 13px;
  margin-right: 10px;
}

section.box-content .press_releases_bx .detail span i {
  margin-right: 5px;
}

section.box-content .table_calendar td {
  height: 50px;
  font-size: 20px;
}

section.box-content .table_calendar td a.event-icon {
  font-size: 15px;
}

.marquee {
  margin: 0 auto;
  width: 100%;
  height: 340px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.marquee:before,
.marquee:after {
  position: absolute;
  top: 0;
  width: 50px;
  height: 30px;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
}

.marquee:after {
  right: 0;
}

.marquee__content {
  animation: marquee 7s linear infinite forwards;
}

.marquee__content:hover {
  animation-play-state: paused;
}

@keyframes marquee {
  20% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(-35%);
  }
}

.subscriber {
  background-color: #123d6a;
  padding: 60px 0 15px 0;
  display: none;
}

.subscriber label {
  font-size: 18px;
  font-weight: 500;
  text-align: right;
  padding-top: 7px;
  color: #fff;
}

.subscriber .form-control {
  height: 40px;
  border: none;
  border-radius: 7px;
  font-size: 18px;
  max-width: 100%;
}

.subscriber .subscribenow {
  width: 100%;
  height: 40px;
  text-align: center;
  background-color: #ff7900;
  border: none;
  color: #fff;
  font-size: 18px;
  border-radius: 25px;
}

section.video {
  padding: 10px 0px 40px 0px;
}

section.video .video_bx {
  position: relative;
}

section.video .video_bx:hover i,
.new_video_gallery .card:hover i.fa-play {
  background: #123d6a;
  color: #fff;
}

section.new_gallery {
  margin-bottom: 40px;
  background: url(../img/gallery_bg.png) no-repeat center center;
  /* background: #f5f5f5; */
  padding: 50px 0px;
  background-attachment: fixed;
}

section.new_video_gallery {
  margin-bottom: 60px;
}

section.video .video_bx i,
.new_video_gallery i.fa-play {
  position: absolute;
  top: 50%;
  font-size: 24px;
  left: 50%;
  background: #fff;
  height: 55px;
  border-radius: 100%;
  width: 55px;
  display: inline-block;
  text-align: center;
  line-height: 55px;
  color: #ff7900;
  transform: translate(-50%, -50%);
}

section.video .no-gutters>.col,
section.video .no-gutters>[class*="col-"] {
  padding: 5px !important;
}

#portfolio .no-gutters>.col,
#portfolio .no-gutters>[class*="col-"],
section.new_gallery .no-gutters>[class*="col-"],
.gallerydetail_page [class*="col-"] {
  padding: 5px !important;
}

.new_gallery_slide .carousel-caption {
  background: #123d6a;
  border-radius: 0px;
  right: 5%;
  left: 5%;
  position: static;
  padding-top: 5px;
  padding-bottom: 5px;
}

.new_gallery_slide .carousel-caption .title {
  font-size: 16px;
  margin-bottom: 5px;
}

.new_gallery_slide button.close {
  position: absolute;
  right: -25px;
  z-index: 999;
  text-shadow: none;
  opacity: 0.9;
  color: #000;
  background: #fff;
  font-size: 25px;
  min-width: 40px;
  border-radius: 40px;
  min-height: 40px;
  top: -25px;
}

.carousel-control-prev-icon {
  background-image: url(../img/arrow_left.png);
  height: 38px;
  width: 20px;
}

.carousel-control-next-icon {
  background-image: url(../img/arrow_right.png);
  height: 38px;
  width: 20px;
}

.top_slider .carousel-control-prev-icon,
.new_gallery_slide .carousel-control-prev-icon {
  background: #123d6a url(../img/arrow_left_white.png);
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  background-size: 12px;
  border-radius: 100%;
}

.top_slider .carousel-control-next-icon,
.new_gallery_slide .carousel-control-next-icon {
  background: #123d6a url(../img/arrow_right_white.png);
  height: 40px;
  width: 40px;
  background-repeat: no-repeat;
  text-align: center;
  background-position: center;
  background-size: 12px;
  border-radius: 100%;
}

.new_gallery_slide button.close:hover {
  color: #fff;
  background: #ff7900;
}

.image_title,
.video_title {
  background: #123d6a;
  position: absolute;
  width: 100%;
  bottom: 0px;
  color: #fff;
  font-size: 100%;
  padding: 8px 15px;
  text-align: center;
}

section.video .video_bx,
#portfolio .pos-rel,
section.new_gallery .pos-rel {
  border-radius: 15px 0 15px 0;
  overflow: hidden;
}

.press_box>[class*="col-"]:last-child {
  border: none;
}

.press_box>[class*="col-"] {
  padding: 0px 40px;
  border-right: solid 1px #e6e6e6;
}

a.more_btn:hover {
  color: #123d6a;
}

a.more_btn {
  text-transform: uppercase;
  color: #ff7900;
  font-size: 14px;
  margin-bottom: 15px;
  display: inline-block;
}

.achivement_caption {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 10px;
  font-size: 120%;
  width: 100%;
  max-width: 360px;
  margin: auto;
  position: absolute;
  left: 50%;
  transform: translatex(-50%);
  top: 50%;
  font-weight: 600;
  border-radius: 15px;
}

.achivement_slider .fa {
  font-size: 35px;
  color: #fff;
  text-shadow: 2px 2px 4px #4c4c4c;
}

.carousel-control-next,
.carousel-control-prev {
  opacity: 0.8;
}

.program_detail_right_box .photos>[class*="col-"] {
  padding: 5px;
  border: solid 1px #f1f1f1;
  margin-bottom: 2px;
}

.program_detail_right_box .photos img {
  border-radius: 5px;
}

.press_releases {
  height: 340px;
  overflow: hidden;
}

.press_releases ul {
  list-style: none;
  position: relative;
}

/*.new_gallery_slide .carousel-control-next, .carousel-control-prev {
  width: auto;
}
*/

.new_gallery_slide .carousel-inner {
  text-align: center;
}

.new_gallery_slide .modal-body {
  padding: 0px;
}

.new_gallery_slide .carousel-control-next {
  right: 20px;
}

.new_gallery_slide .carousel-control-prev {
  left: 20px;
}

.page_write_cm {
  max-width: 500px;
  margin: 50px auto;
}

a.dropdown-item:hover {
  color: #000000;
}

.page-item.active .page-link {
  background-color: #ff7900;
  border-color: #ff7900;
}

.program_detail_right .media a {
  color: #000;
  font-size: 90%;
}

.program_detail_left .carousel-item {
  border: solid 1px #e4e4e4;
}

.awards_page .image_title {
  /*position: relative;*/
  background: #f1f1f1;
  color: #000;
  position: absolute;
  bottom: 0px;
  min-height: 36px;
}

.awards_page .image_title a {
  color: #000;
}

.awards_page .image_title a span {
  font-size: 12px;
  color: #eb0000;
  display: block;
}

.awards_page .award_box img {
  max-height: 230px;
  margin-bottom: 35px;
}

.awards_page .new_gallery_slide img {
  max-height: 500px;
}

.new_gallery .carousel-item img {
  max-height: 700px;
}

.inner_subscriber {
  padding: 15px 0px;
}

.achivement_slider img {
  max-width: 100%;
  max-height: 335px;
}

/* .achivement_slider .carousel-item {
  min-height: 335px;
} */

.ngx-datepicker-calendar-container {
  z-index: 999;
}

.cmrelief .card {
  border: none !important;
  margin: 0px 10px 30px 10px !important;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.1);
}

.program_detail_right_box .press_releases {
  height: auto;
}

.form-control {
  font-size: 14px;
  min-height: 40px;
}

.program_detail_left .carousel .carousel-item img {
  width: auto !important;
  margin: auto;
  max-height: 360px;
  max-width: 100% !important;
}

.ngx-datepicker-container[_ngcontent-ng-cli-universal-c4] .ngx-datepicker-calendar-container[_ngcontent-ng-cli-universal-c4] .main-calendar-container[_ngcontent-ng-cli-universal-c4] .main-calendar-days[_ngcontent-ng-cli-universal-c4] .day-unit.is-selected[_ngcontent-ng-cli-universal-c4],
.ngx-datepicker-container[_ngcontent-ng-cli-universal-c4] .ngx-datepicker-calendar-container[_ngcontent-ng-cli-universal-c4] .main-calendar-container[_ngcontent-ng-cli-universal-c4] .main-calendar-days[_ngcontent-ng-cli-universal-c4] .year-unit.is-selected[_ngcontent-ng-cli-universal-c4],
.ngx-datepicker-container[_ngcontent-ng-cli-universal-c4] .ngx-datepicker-calendar-container[_ngcontent-ng-cli-universal-c4] .main-calendar-container[_ngcontent-ng-cli-universal-c4] .main-calendar-years[_ngcontent-ng-cli-universal-c4] .day-unit.is-selected[_ngcontent-ng-cli-universal-c4],
.ngx-datepicker-container[_ngcontent-ng-cli-universal-c4] .ngx-datepicker-calendar-container[_ngcontent-ng-cli-universal-c4] .main-calendar-container[_ngcontent-ng-cli-universal-c4] .main-calendar-years[_ngcontent-ng-cli-universal-c4] .year-unit.is-selected[_ngcontent-ng-cli-universal-c4] {
  background: #123d6a !important;
  color: #fff;
}

.program_list .ngx-datepicker-input {
  border: none !important;
}

.modal.show .modal-dialog {
  -webkit-transform: translate(0, -40%);
  transform: translate(0, -40%);
  top: 40%;
}

.program_detail_left .carousel {
  max-width: 580px;
  margin: auto;
}

.program_list #programmes .programme-content {
  width: 100%;
}

section.program_list #programmes .box {
  padding: 10px;
}

/*LOGIN PAGE CSS*/

/* .login_page {
  background-image: url('../images/login_bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}

  .login_page:before {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    content: "";
    left: 0px;
    height: 100%;
    width: 100%;
  } */

/* .login_page .card {
    height: 260px;
    margin-top: auto;
    margin-bottom: auto;
    width: 400px;
    background-color: #123d6a;
  }

  .login_page .card-header h3 {
    color: white;
    margin: 0px;
  }

  .login_page .input-group-prepend span {
    width: 44px;
    background-color: #102844;
    color: #fff;
    border: 0 !important;
  }

  .login_page input:focus {
    outline: 0 0 0 0 !important;
    box-shadow: 0 0 0 0 !important;
  }

  .login_page .remember {
    color: white;
  }

    .login_page .remember input {
      width: 20px;
      height: 20px;
      margin-left: 15px;
      margin-right: 5px;
    }

  .login_page .login_btn {
    color: black;
    background-color: #dc3545;
    width: 100px;
    color: #fff;
  }

    .login_page .login_btn:hover {
      color: #fff;
      background-color: #FF7900;
      border-color: #FF7900;
    } */

.no-record {
  background: #f9f9f9;
  margin: 40px auto;
  max-width: 500px;
  padding: 50px 50px;
  text-align: center;
  font-size: 30px;
  border-radius: 10px;
  color: #ea3a1e;
}

.nodal-office-info p {
  margin-bottom: 15px;
  font-size: 13px;
}

.nodal-office-info a:before {
  display: none;
}

.nodal-office-info img {
  background: #fff;
  /* max-width: 125px; */
  margin-top: 20px;
  border-radius: 5px;
  padding: 10px;
}

ul.footer_social li a img {
  background: transparent;
  margin: 0px;
  padding: 0px;
  max-width: 32px;
}

.offer-popup_slider img {
  max-height: 584px;
}

/*LOGIN PAGE CSS END*/

/* Page Breadcrumbs End */

#myModal .modal-dialog .modal-header {
  padding: 6px;
  border: none;
}

#myModal .modal-dialog .modal-header .close {
  font-size: 26px;
  position: absolute;
  right: -13px;
  top: -16px;
  background: rgba(0, 0, 0, 0.73);
  border-radius: 100%;
  color: #fff;
  height: 50px;
  width: 50px;
  display: inline-block;
  line-height: 19px;
  opacity: 1;
  z-index: 99999;
}

/*----------------------------------------------------
  Responsive Start
  ----------------------------------------------------*/
.programmes-photo {
  width: 35%;
  float: left;
  text-align: center;
}

.programmes-photo img {
  border-radius: 20px;
  border-radius: 20px;
  height: 170px;
  object-fit: cover;
  object-position: top center;
  width: auto;
}

.programmes-content {
  width: 63%;
  float: right;
  padding-top: 10px;
}

.programmes-content h3 {
  font-size: 16px;
  color: #094f82;
  font-weight: 800;
  margin-bottom: 5px;
}

.time-block {
  padding: 0px 0px 5px;
}

.cm-programmes {
  width: 100%;
  float: left;
  padding-bottom: 30px;
}

.cm-address h4 {
  font-size: 18px;
  font-weight: 700;
  margin-top: 15px;
  margin-bottom: 10px;
}

.top-page-title .fa:hover,
.svg-img-icon path {
  color: #fa8620;
}

.new-logo .img-3 {
  float: left;
}

.new-logo .img-3 {
  float: left;
  margin-right: 10px;
}

.new-logo .logo-text {
  float: left;
  margin-top: 10px;
}

.new-logo .logo-text .logo-top-text {
  color: #000;
  font-weight: 700;
  font-size: 18px;
  text-transform: uppercase;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  margin-bottom: 5px;
}

.logo-bottom-text {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}

.cmo-photo-title {
  position: absolute;
  padding-left: 25px;
  padding-top: 15px;
  z-index: 1;
  color: #094f82;
  font-size: 20px;
  font-weight: 700;
}

.cmo-photo-title img {
  margin-right: 5px;
}

.cmo-photo-title span {
  font-weight: 300;
}

.profile-block1 {
  background: url(../images/banner-2.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 15px;
  margin: 60px 15px;
  padding: 40px;
}

.profile-block-in-2 {
  background: url(../images/banner-2.png) no-repeat;
  background-size: 100% 100%;
  border-radius: 15px;
  padding: 40px;
  margin-bottom: 30px;
}

.profile-first {
  border-right: 1px solid #aeaeae;
}

.profile-first img {
  width: 200px;
  float: left;
  margin-right: 20px;
  border-radius: 15px;
  border: 5px solid #fff;
}

.profile-in {
  /* float: left; */
  padding: 15px 0px;
}

.profile-in h2 {
  padding-bottom: 0px;
  font-weight: 600;
  margin-bottom: 10px;
  font-size: 30px;
}

.profile-in p {
  padding-bottom: 0px;
  margin-bottom: 0px;
  line-height: 30px;
  font-size: 18px;
}

.profile-in-2 {
  padding: 15px 0px;
  padding-left: 70px;
  font-size: 16px;
}

.profile-in-2 h3 {
  font-size: 26px;
  font-weight: 600;
}

.profile-block-2 .row {
  margin: 0px;
  padding: 0px;
}

.profile-block-2 .profile-first {
  border-right: 0px solid #aeaeae;
}

@media (min-width: 768px) {
  .new_gallery_slide .modal-lg {
    max-width: 72%;
  }

  .awards_page .modal-lg {
    max-width: 600px;
  }

  .container {
    max-width: 95%;
  }
}

@media (max-width: 1600px) {
  #programmes .programme-content>a {
    font-size: 12px;
  }

  #programmes .box .date {
    font-size: 12px;
  }

  #nav-menu-container,
  .navbar-nav {
    margin: 0 140px 0 0;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 10px;
    padding-left: 10px;
  }

  .cm-rajasthan .cm_box h3 {
    font-size: 15px;
    margin: 12px 0px;
  }

  .cm-rajasthan .cm_box h4 {
    font-size: 14px;
  }

  .cm-rajasthan .cm_box ul li {
    padding: 1px 0px;
    font-size: 12px;
  }

  .bg-scroll.pos-rel {
    padding: 2.5rem 0;
  }

  #header #logo img {
    max-width: 300px;
  }

  #logo {
    top: -5px;
  }
}

@media (max-width: 1366px) {
  a.budget-icon img {
    left: 29%;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 100%;
    padding: 0px 5%;
  }

  section.program_list_detail.page_search [class*="col-"] {
    max-width: 20%;
  }
}

@media (max-width: 1279px) {
  .sticky .cm-profile {
    top: -23px;
    right: 0px;
  }

  .navbar {
    padding: 0px;
  }

  #header #logo img {
    max-width: 200px;
  }

  .bg-scroll.pos-rel {
    background-size: cover;
    padding: 3rem 0;
  }

  .breadcrumbs-description {
    max-width: 100%;
  }

  body {
    font-size: 13px;
  }

  #logo {
    z-index: 9;
    width: 240px;
  }

  .intro-page #logo {
    width: 290px;
    left: 0;
  }

  .nav-menu a {
    padding: 6px 10px;
  }

  .cm-profile {
    width: 75px;
    right: 0;
    z-index: 9;
  }

  #header .container {
    padding: 10px 0;
  }

  #header {
    height: 60px;
  }

  #header:before,
  #header:after {
    width: 60px;
    height: 60px;
  }

  #nav-menu-container {
    margin: 0 80px 0 0;
  }

  .is-sticky #logo {
    width: 164px;
  }

  .is-sticky .cm-profile {
    width: 50px;
    right: 0;
  }

  .four-boxes#programmes .box {
    padding: 15px;
  }

  .four-boxes#programmes .box .icon {
    margin-bottom: 5px;
  }

  #about,
  #programmes,
  #testimonials,
  #call-to-action,
  #portfolio {
    padding: 20px 0;
  }

  #about .content h2 {
    font-size: 18px;
  }

  #about .content h3 {
    font-size: 14px;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0 0 10px 0;
  }

  #about .content ul li {
    padding-bottom: 5px;
  }

  .section-header h2 {
    font-size: 20px;
    padding-bottom: 10px;
  }

  #programmes .box h4 {
    font-size: 14px;
  }

  #testimonials .testimonial-item {
    padding: 10px;
  }

  #testimonials .testimonial-item p {
    font-size: 14px;
  }

  #call-to-action .cta-title {
    font-size: 22px;
    margin-bottom: 10px;
  }

  #contact .contact-info h3 {
    font-size: 13px;
    margin-bottom: 10px;
  }

  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    padding: 15px 0;
  }

  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    margin-bottom: 15px;
  }

  .footer-links {
    padding: 10px;
  }

  .footer-links ul li {
    font-size: 12px;
    margin-bottom: 3px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  a.budget-icon img {
    left: 25%;
    max-width: 94px;
    top: 17px;
  }

  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 7px;
    padding-left: 7px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  a.budget-icon img {
    left: 43%;
    max-width: 94px;
    top: 12px;
  }

  .navbar-light .navbar-nav .nav-link span {
    display: none;
  }
}

@media (max-width: 992px) {
  .relief-bloc2 {
    width: 100% !important;
    float: none !important;
  }

  .navbar-toggler-icon {
    background-size: 35px;
  }

  ul.navbar-nav.ml-auto {
    margin-top: 33px;
    padding: 0px 15px;
  }

  .program-img-bx {
    height: auto;
  }

  .sticky #logo {
    top: -7px;
    left: 17px;
  }

  #topbar {
    text-align: center !important;
  }

  .contact-info span {
    display: none;
  }

  .press_releases {
    height: 340px;
  }

  .four-boxes#programmes .box:after {
    display: none;
  }

  .sticky .cm-profile {
    top: -5px;
    width: 45px;
    right: 50px;
  }

  .navbar {
    margin-bottom: 0px;
    padding: 0px;
    height: auto;
    top: -8px;
  }

  .cm-profile {
    right: 0px;
    top: 44px;
  }

  .dropdown-toggle::after {
    float: right;
    margin: 7px;
  }

  .navbar-toggler {
    padding: 0px;
    font-size: 2rem;
    border: none;
  }

  #nav-menu-container,
  .navbar-nav {
    float: left;
    margin: 0px;
    width: 100%;
  }

  .navbar-nav .nav-link {
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
  }

  #programmes .pbox .icon {
    width: 42%;
  }

  .back-to-top {
    bottom: 15px;
  }

  #header {
    padding: 10px 0;
    height: 65px;
  }

  .is-sticky #header {
    height: 60px;
  }

  #header:before,
  #header:after {
    width: 65px;
    height: 65px;
    display: none;
  }

  #header #logo h1 {
    font-size: 34px;
  }

  #logo {
    width: 150px;
    top: -6px;
    left: 0px;
  }

  .intro-page #logo {
    width: 250px;
    left: 0;
  }

  .is-sticky #logo {
    width: 130px;
    top: -5px;
  }

  #nav-menu-container {
    display: none;
  }

  #mobile-nav-toggle {
    display: inline-block;
  }

  .cm-profile {
    width: 52px;
    right: 60px;
    z-index: 9;
    top: -7px;
  }

  .is-sticky .cm-profile {
    width: 42px;
    right: 40px;
    top: -2px;
  }

  #about .about-img {
    height: auto;
  }

  #about .about-img img {
    margin-left: 0;
    padding-bottom: 30px;
  }

  .section-header h2 {
    font-size: 20px;
  }

  #call-to-action .cta-btn {
    font-size: 14px;
    padding: 7px 20px;
    margin: 10px;
    border: 1px solid #fff;
  }

  #contact .contact-phone {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
  }

  .right-to-info img {
    width: 94px;
  }

  #call-to-action .cta-title {
    font-size: 20px;
  }

  #call-to-action .cta-btn-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: end;
    -webkit-justify-content: center;
    -ms-flex-pack: end;
    justify-content: center;
  }

  #intro,
  #intro #intro-carousel .item {
    height: auto;
  }

  .four-boxes#programmes {
    margin-top: 0px;
  }

  .four-boxes#programmes .box {
    margin-bottom: 15px;
  }

  .top_slider .carousel-item img {
    height: auto;
  }
}

@media (max-width: 992px) {
  a.budget-icon img {
    max-width: 108px;
    position: absolute;
    left: 60%;
    top: 62px;
    z-index: 999;
    transform: translateX(-60%);
  }
}

@media (max-width: 767px) {
  .relief-block {
    margin-bottom: 15px;
  }

  .navbar-light .navbar-nav .nav-link span {
    display: none;
  }

  #logo {
    top: 0px;
  }

  #header #logo img {
    max-width: 150px;
  }

  a.budget-icon img {
    max-width: 108px;
    position: absolute;
    left: 60%;
    top: 62px;
    z-index: 999;
    transform: translateX(-60%);
  }

  .sticky #logo {
    top: 0px;
    left: 15px;
  }

  .program_detail_right_box .photos>[class*="col-"] {
    width: 50%;
  }

  .breadcrumb {
    padding-left: 15px;
  }

  .msg_text {
    margin-left: 0px;
  }

  .bg-scroll.pos-rel {
    margin-top: 0;
    background-size: cover !important;
    padding: 15px 10px;
  }

  .section-header h2::before {
    left: 50%;
    transform: translateX(-50%);
  }

  section.box-content .press_releases_bx .detail a {
    font-size: 14px;
  }

  .cmo-message h3 {
    line-height: 20px;
  }

  .cmo-message .d-flex {
    display: block !important;
    text-align: center;
  }

  #about .cmo-img {
    max-width: 130px;
    margin: auto;
    margin-bottom: 22px;
  }

  .msg_text .name span {
    font-size: 14px;
    display: block;
  }

  section.program_list .card p {
    white-space: normal;
  }

  .four-boxes#programmes .box h4 i {
    display: none;
  }

  section.program_list .input-group.col-xl-5.m-auto .form-control {
    width: 100%;
    margin-bottom: 5px;
  }

  section.program_list .input-group.col-xl-5.m-auto .input-group-append {
    width: 100%;
  }

  section.program_list .input-group.col-xl-5.m-auto .input-group-append button.btn {
    width: 100%;
  }

  .program_list_detail .program_detail_left h3 {
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 25px;
  }

  .program_detail_right {
    margin-top: 30px;
  }

  .program-img-bx {
    height: auto;
  }

  #about,
  #call-to-action,
  #portfolio,
  #programmes,
  #testimonials {
    padding: 0px;
  }

  .pagination>li>a,
  .pagination>li>span {
    padding: 5px;
  }

  .input-group[class*="col-"] {
    padding-right: 15px;
    padding-left: 15px;
  }

  .new_gallery_slide button.close {
    right: 0px;
  }

  .profile_box {
    margin-bottom: 25px;
  }

  .profile_box .name {
    font-size: 20px;
  }

  .press_box>[class*="col-"] {
    padding: 0px 15px;
    margin-bottom: 20px;
  }

  .press_releases {
    height: 344px;
  }

  .cm-profile {
    display: none;
  }

  .dropdown-toggle::after {
    float: right;
    margin: 7px;
  }

  .navbar-toggler {
    padding: 0;
    font-size: 2rem;
    border: none;
    position: absolute;
    right: 12px;
    top: 6px;
  }

  .navbar {
    display: inline-table;
    width: 100%;
    top: -17px;
  }

  #footer {
    text-align: center;
  }

  ul.footer_social {
    text-align: center;
    margin: 10px 0px 0px 0px;
  }

  #header-sticky-wrapper:after,
  .top_border:after {
    height: 4px;
    bottom: -4px;
  }

  .four-boxes#programmes {
    margin-top: 0px;
  }

  #nav-menu-container,
  .navbar-nav {
    float: left;
    margin: 0px;
    width: 100%;
  }

  .navbar-nav .nav-link {
    padding-right: 10px;
    padding-left: 10px;
    width: 100%;
  }

  section.program_list .card {
    flex: 100%;
  }

  #mobile-nav-toggle {
    margin: 17px 15px 0 0;
  }

  #logo {
    left: 15px;
  }

  .is-sticky #logo {
    left: 15px;
  }

  .cm-profile {
    right: 55px;
  }

  .is-sticky .cm-profile {
    right: 55px;
  }

  #intro .intro-content h2 {
    font-size: 34px;
  }

  #programmes .box .box {
    margin-bottom: 20px;
  }

  #programmes .box .icon {
    float: none;
    text-align: center;
    padding-bottom: 15px;
    width: 100%;
    height: auto;
  }

  #programmes .box h4,
  #programmes .box p {
    margin-left: 0;
    text-align: center;
    font-size: 13px;
    font-weight: lighter;
  }

  .four-boxes#programmes .box {
    padding: 0px;
  }

  .four-boxes#programmes .box:after {
    display: none;
  }

  .four-boxes#programmes {
    padding: 15px 0px;
  }

  #testimonials .testimonial-item {
    margin: 5px 0;
  }

  #contact .contact-address,
  #contact .contact-phone,
  #contact .contact-email {
    border-top: 1px solid #ddd;
    margin-bottom: 10px;
  }

  #contact .contact-phone {
    border-left: none;
    border-right: none;
  }

  .footer-links h4 {
    margin-top: 15px;
  }

  .footer-links h4 {
    margin-bottom: 10px;
  }

  #call-to-action .cta-title {
    font-size: 18px;
  }

  #call-to-action .cta-btn {
    font-size: 13px;
    padding: 6px 16px;
    margin: 8px;
  }

  #call-to-action .cta-btn-container {
    justify-content: center;
  }

  .right-to-info {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .right-to-info-img {
    width: 30px;
    margin-right: 0;
    margin-bottom: 15px;
  }

  #programmes .programme-content {
    text-align: center;
  }
}

@media (max-width: 550px) {
  #programmes .programme-content {
    width: 100%;
  }

  #programmes .pbox {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  #programmes .box {
    padding: 15px;
  }

  #programmes .box .icon {
    margin-right: 0;
    width: 100%;
    height: auto;
  }

  #programmes .box span {
    font-size: 12px;
    line-height: 20px;
    padding-right: 7px;
    margin-right: 5px;
  }

  .section-header {
    margin-bottom: 20px;
    text-align: center;
  }

  .section-header h2::before {
    background-position: center top;
  }

  .section-header h2 {
    font-size: 18px;
  }
}

@media (max-width: 1600px) {
  .top-add-news img {
    max-width: 154px;
  }

  a.budget-icon img {
    max-width: 120px;
    top: 0;
  }
}

.navbar-nav a img {
  max-width: 180px;
  position: relative;
  top: -27px;
}

.add-icon img {
  max-width: 145px;
  margin-right: 25px;
}

@media (max-width: 992px) {
  .add-icon img {
    position: absolute;
    right: 10px;
    transform: translatex(-50%);
    top: 11px;
  }

  .add-icon img {
    max-width: 95px;
    margin-right: 25px;
  }

  .navbar-collapse {
    background: #fff;
    width: 100%;
    margin-top: 24px;
    border-top: solid 1px #ccc;
  }
}

/* Advert CSS Start */
.col-slider {
  -webkit-box-flex: 1;
  flex: 1 1 88%;
}

iframe {
  border: none;
}

.col-advertisement {
  -webkit-box-flex: 1;
  flex: 1 1 12%;
  border-left: 4px double #ebb512;
}

.web-advertisement-card {
  max-height: 796px;
  overflow: auto;
  height: 100%;
  background: #fff0ed;
  padding: 15px;
  white-space: normal;
  word-break: break-all;
}

.web-advertisement-card-items-img {
  border: 2px solid #d4e2f3;
  display: block;
  border-radius: 15px;
  overflow: hidden;
  background: #fff;
  text-align: center;
  padding: 15px;
  box-shadow: 0 0 20px #cfcfcf;
}

.web-advertisement-card-items-img img {
  max-height: 230px;
  margin: auto;
}

.web-advertisement-card-items p {
  margin: 0;
  text-align: left;
  font-size: 100%;
}

.web-advertisement-card-items {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 0 10px #c5c5c5;
  color: #000;
  margin-bottom: 15px;
}

.web-advertisement-adv-btn {
  display: block;
  background: #151d4c;
  padding: 8px 4px;
  border-radius: 15px;
  text-align: center;
  color: #fff !important;
  margin-bottom: 12px;
  font-size: 100%;
}

.web-advertisement-card .web-advertisement-adv-btn:nth-child(2) {
  background: #37743a;
}

.web-advertisement-card .web-advertisement-adv-btn:nth-child(3) {
  background: #673ab7;
}

.web-advertisement-card .web-advertisement-adv-btn:nth-child(4) {
  background: #e91e63;
}

.web-advertisement-card .web-advertisement-adv-btn:nth-child(5) {
  background: #607d8b;
}

.web-advertisement-card .web-advertisement-adv-btn:nth-child(6) {
  background: #f44336;
}

.web-advertisement-card .web-advertisement-adv-btn:nth-child(7) {
  background: #9f8e00;
}

.text-no-record {
  font-size: 20px;
  text-align: center;
  color: #d70000;
  margin: 10px 0px;
}

.web-advertisement-card::-webkit-scrollbar {
  width: 6px;
}

.web-advertisement-card::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.web-advertisement-card::-webkit-scrollbar-thumb {
  background-color: #153ead;
  outline: none;
}

@media screen and (max-width: 1450px) {
  .col-slider {
    flex: 1 1 65%;
  }
}

@media screen and (max-width: 1000px) {
  .col-slider {
    flex: 1 1 100%;
  }
}

/* Advert CSS End */

app-home-mainpress .row.press_box .carousel-inner {
  border-radius: 0;
  background: transparent;
}

/* Anamika CSS start  */

/* New css Start */
.title {
  font-size: 28px;
  font-weight: 500;
  margin-bottom: 35px !important;
}

.title img {
  max-width: 40px;
}

/* New css End */

section.home-new-program .home-new-program-bg-white {
  background: #fff;
  padding: 10px 15px;
  border-radius: 20px;
}

section.home-new-program .home-new-program-bg-white .d-flex.align-items-center.mb-3.justify-content-between,
section.home-service .d-flex.align-items-center.mb-4.justify-content-between {
  display: block !important;
  text-align: center;
}

section.home-new-program .home-new-program-bg-white .d-flex.align-items-center.mb-3.justify-content-between h1.title.mb-0 {
  margin-bottom: 15px !important;
}

section.home-new-program {
  /* background: url(../images/government-decisions-bg.png) repeat center center; */
  padding: 25px 25px;
  background-attachment: fixed;
}

section.home-new-program .home-new-program-bg-white {
  background: #fff;
  padding: 10px 15px;
  border-radius: 20px;
}

.about-yajona-text .section-header.mb-1 {
  margin-top: 35px !important;
}

section.top_news {
  background: #123d6a;
  text-align: center;
  color: #fff;
  padding: 6px;
  font-weight: 600;
  height: 40px;
}

section.top_news ul {
  margin: 0;
  padding: 0;
  display: -webkit-box;
  display: flex;
}

section.top_news ul li {
  margin-right: 50px;
  line-height: 30px;
}

section.top_news ul li a {
  color: #fff;
}

.footer-add-image {
  display: flex;
  justify-content: center;
}

.new-card-design-1 {
  box-shadow: 0 0 20px #d3d3d3;
  border-radius: 20px;
  overflow: hidden;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.new-card-design-1 h2 {
  font-size: 150%;
  font-weight: 600;
}

.new-card-design-1 .new-card-design-1-header p {
  background: #ffdcd6;
  font-weight: 600;
  font-size: 130%;
  color: #000;
  border-radius: 5px;
  padding: 2px 5px;
  margin-left: 40px !important;
}

.new-card-design-1 .btn-new-theme {
  font-size: 120%;
  text-transform: capitalize;
  background: #2b468b;
  color: #fff;
}

span.acievement-total-record {
  background: #123d6a;
  height: 35px;
  width: 35px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  flex: 0 0 35px;
  font-size: 20px !important;
}

.new-achivement p,
.new-achivement pre {
  font-size: 15px;
  white-space: normal;
  line-height: 24px;
  margin: 0px;
}

.new-achivement-icon {
  flex: 0 0 50px;
  max-width: 50px;
  margin-right: 10px;
}

img {
  max-width: 100%;
}

section.program_list_detail .carousel-item img {
  max-width: 100%;
  max-height: 600px;
}

/* Anamika CSS End  */

/* Form CSS Start  */
.page-rajvision-feedback h2 {
  font-size: 32px;
  font-weight: 700;
  text-align: center;
}

.form-box label {
  font-weight: 500;
  font-size: 15px;
  color: #000;
}

.form-box {
  padding: 24px;
  border-radius: 15px;
  border: dotted 2px #123d6a;
  box-shadow: 0 0 20px #e1e1e1;
}

.form-title {
  width: 100%;
  font-size: 24px;
  font-weight: 600;
  margin: 10px 0px 10px;
  color: #123d6a;
}

.ngx-datepicker-input {
  font-size: 14px;
  min-height: 40px;
  display: block;
  width: 100% !important;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ngx-datepicker-container {
  position: relative;
}

.dateIcon {
  position: absolute;
  top: 8px;
  right: 5px;
}

.upload-btn-wrapper {
  padding: 2px 5px;
  border: solid 1px #e6e6e6;
  border-radius: 5px;
  background: #fff;
  position: relative;
}

.file-del-icon {
  position: absolute;
  right: 19px;
  top: -3px;
  color: red;
  font-size: 18px;
}

.file-del-icon .btn-soft-copy {
  color: #123d6a;
  font-size: 16px;
  margin-right: 8px;
}

.custom-file-upload {
  margin: 0px;
  background: #ededed;
  width: 100%;
  padding: 9px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
}

.btn-img-download {
  background: rgb(19, 93, 164, 0.9);
  border: none;
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: #fff;
  height: 35px;
  width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000%;
  font-size: 16px;
  cursor: pointer;
}

.uploader-gallery-list-items img {
  max-height: 290px;
  height: 290px;
  object-fit: cover;
  width: 100%;
  border-radius: 0px !important;
}

section.uploader-gallery {
  padding: 40px 0px;
}

.uploader-gallery-items {
  position: relative;
}

span.uploader-gallery-items-date {
  position: absolute;
  right: 10px;
  background: rgb(0, 0, 0, 0.6);
  top: 10px;
  padding: 2px 8px;
  color: #fff;
  border-radius: 10px;
  font-size: 80%;
}

.uploader-gallery-items-img {
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background: #f4f9ff;
}

.uploader-gallery-items-img img {
  height: 300px;
  object-fit: cover;
}

.uploader-gallery-items-caption {
  margin-top: 5px;
  color: #000;
  font-size: 105%;
  line-height: 22px;
  font-weight: 500;
  position: absolute;
  background: rgba(255, 255, 255, 0.7);
  width: 100%;
  bottom: 10px;
  padding: 5px;
  font-weight: 600;
}

/* Form CSS end  */
header.new-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0px;
  padding: 0px 15px;
  padding-left: 5%;
  background: linear-gradient(180deg, #ffddbd 0%, #fff 100%);
  position: relative;
}

.new-logo {
  display: block;
  top: 0px;
  position: relative;
  z-index: 999;
  padding: 5px 0px;
  float: left;
}

/* .header-cm-img {
  height: 90px;
  width: 90px;
  background: #fff;
  border-radius: 100%;
  overflow: hidden;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 1px #ff7900;
} */

.header-cm-img {
  height: 95px;
  width: 95px;
  overflow: hidden;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.new-menu a {
  color: #575757;
  font-size: 22px;
}

.new-menu a span {
  font-weight: 300;
}

.top_slider .carousel-item {
  /* background: #fff8f1; */
  background: url(../img/gallery_bg.png) no-repeat center center;
  text-align: center;
}

.home-pro-new-card {
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  cursor: pointer;
}

.home-pro-new-card-img {
  height: 250px;
  background: #fff8f1;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  max-height: 250px;
}

.home-pro-new-card .home-pro-new-card-img-info {
  position: absolute;
  bottom: 0;
  width: 100%;
  /* background: rgba(0,0,0,0.5); */
  height: 100%;
  z-index: 9;
  display: flex;
  align-items: flex-end;
}

.home-pro-new-card .home-pro-new-card-img-info::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0;
  /* background-image: linear-gradient(to top, #000000, #000000, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff, #ffffff); */
  background: linear-gradient(0deg, #0f3a48 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
  opacity: 0.5;
}

.home-pro-new-card .home-pro-new-card-img-info h4,
.home-pro-new-card .home-pro-new-card-img-info h4 a {
  color: #fff;
  font-size: 16px;
  margin: 0px;
  font-weight: 600;
  display: block;
  padding: 15px;
  z-index: 9;
  width: 100%;
}

.home-pro-new-card:hover .home-pro-new-card-img-info:before {
  opacity: 0.8;
  transition: 0.3s;
}

.home-pro-new-card:hover h4 {
  transform: translatey(-50%);
  transition: 0.3s;
}

.home-pro-new-card-date {
  position: absolute;
  top: 10px;
  background: rgb(255, 121, 0, 0.7);
  right: 0px;
  padding: 2px 5px;
  color: #fff;
  font-size: 12px;
  z-index: 999;
}

.new-header-items {
  flex: 1 1 33%;
}

.new-header-items2 {
  flex: 1 1 33% !important;
}

.header-cm {
  text-align: center;
}

.new-menu {
  text-align: right;
}

.section-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.section-header-action a {
  background: #fff;
  padding: 5px 15px;
  border-radius: 30px;
  border: solid 1px #ed801d;
  color: #000;
  box-shadow: 0 0 20px #e7e7e7;
}

.section-header-action a:hover {
  border-color: #123d6a;
}

.new_gallery-items {
  display: flex;
  align-items: center;
  color: #000;
  font-size: 110%;
}

.new_gallery-items-img {
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 160px;
  background: #000;
  margin-right: 15px;
  height: 160px;
}

.new_gallery-items-img img {
  max-height: 160px;
}

section.new_gallery .container,
section.box-content .container,
.social-engagements .container,
.new-contact-design .container {
  max-width: 1400px;
}

section.homepage-about {
  background: linear-gradient(180deg, #ffc590 0%, #fff 100%);
  padding: 20px 0px;
}

section.homepage-about .homepage-about-wrapper {
  display: flex;
  align-items: center;
  max-width: 1400px;
  margin: auto;
  padding: 0px 15px;
}

section.homepage-about .homepage-about-img {
  height: 200px;
  width: 200px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
  border: solid 1px #ff7900;
  min-width: 200px;
  margin-right: 30px;
  margin-top: -100px;
  z-index: 9;
  box-shadow: 0 0 20px #ffd8b3;
}

.homepage-about-info p {
  font-size: 22px;
  line-height: 30px;
  margin: 0px;
  color: #000;
}

.homepage-about-info strong {
  font-size: 20px;
  color: #ff7900;
  font-weight: 500;
}

.homepage-about-info small {
  font-size: 13px;
  color: #000;
}

.social-engagements {
  background: url(../img/social_bg.png) no-repeat center center;
  padding: 70px 0px;
  background-attachment: fixed;
}

.social-engagements-items img {
  border-radius: 15px;
}

.social-engagements-items {
  background: #fff;
  display: block;
  border: dotted 2px #ff7900;
  border-radius: 20px;
  overflow: hidden;
  padding: 15px;
}

.new_gallery .card {
  transition: 0.3s;
}

.new_gallery .card:hover {
  box-shadow: 0 0 20px #e7e7e7;
  transform: translatey(-10px);
  transition: 0.3s;
}

#SideMenu {
  background: #123d6a;
  position: fixed;
  right: 0;
  height: 100vh;
  width: 260px;
  top: 0;
  bottom: 0;
  z-index: 99999;
  box-shadow: 0 0 15px #adadad;
  /* display: none; */
  transition: 0.3s;
  transform: translateX(260px);
}

.sidebar-open #SideMenu {
  display: block;
  transform: translateX(0px);
}

#SideMenu .sidemenu-close {
  color: #9d9d9d;
  font-size: 25px;
  text-align: right;
  display: block;
  padding: 5px 10px;
}

ul.side-mainmenu {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

ul.side-mainmenu li {
  position: relative;
}

ul.side-mainmenu>li>a {
  color: #fff;
  padding: 10px 20px;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

ul.side-mainmenu>li>a:hover {
  background: rgba(0, 0, 0, 0.2);
}

ul.side-submenu {
  display: none;
  position: absolute;
  left: -195px;
  background: linear-gradient(180deg, #ffddbd 0%, #fff 100%);
  top: 0;
  width: 195px;
  border-radius: 15px 0px 0px 15px;
  padding: 9px 10px 8px 27px;
  border: solid 1px #ff7900;
  border-right: none;
}

ul.side-submenu a {
  color: #000;
  font-size: 15px;
  display: block;
  padding: 5px;
  font-weight: 600;
}

ul.side-mainmenu li a:hover+ul.side-submenu,
ul.side-submenu:hover {
  display: block;
}

.navbar {
  padding: 0px;
}

.g-photo {
  cursor: pointer;
}

.galleryBlock .modal-header {
  margin: 0px;
  padding: 0px;
}

.galleryBlock .close-in {
  width: 50px;
  height: 50px;
  margin-top: -32px !important;
  margin-right: -20px !important;
}

@media (max-width: 992px) {
  section.homepage-about .homepage-about-img {
    height: 150px;
    width: 150px;
    min-width: 150px;
    margin-right: 20px;
  }

  .homepage-about-info p {
    font-size: 20px;
    line-height: 24px;
  }

  .new_gallery-items-img {
    max-width: 100px;
    margin-right: 10px;
    height: 110px;
  }
}

#SideMenu .sidemenu-close {
  color: #9d9d9d;
  font-size: 25px;
  text-align: right;
  display: inline-flex;
  padding: 10px 10px;
  align-items: center;
  justify-content: flex-end;
}

.new-contact-design {
  background: url(../img/social_bg.png) no-repeat center center;
  padding: 70px 0px;
  background-attachment: fixed;
}

.new-contact-items {
  border: dotted 2px #ff7900;
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  text-align: center;
  display: block;
  height: 100%;
}

.new-contact-items .new-contact-items-icon i {
  font-size: 50px;
}

.new-contact-items a {
  color: #000;
  font-size: 14px;
}

.formercm-text-2 ul {
  padding: 0px;
  margin: 0px;
  padding-left: 15px;
  float: left;
  width: 100%;
}

.formercm-text-2 ul li {
  list-style: none;
  float: left;
  padding: 3px;
}

.formercm-text-2 ul li img {
  width: 50px;
  height: 50px;
}

.governance-blog-2 h3 {
  color: #094f82;
  font-weight: 700;
  padding-bottom: 0px;
  margin-bottom: 10px;
  font-size: 24px;
  /* text-align: center; */
}

.text-btn-1 {
  text-align: center;
}

body .gallerydetail_page [class*="col-"] {
  padding: 15px !important;
}

.governance-blog-pro {
  min-height: 473px;
}

@media (max-width: 767px) {
  .header-cm-img {
    height: 75px;
    width: 75px;
  }

  header.new-header {
    flex-wrap: wrap;
    justify-content: center;
    padding: 0px;
  }

  section.homepage-about .homepage-about-wrapper {
    flex-wrap: wrap;
  }

  section.homepage-about .homepage-about-img {
    height: 115px;
    width: 115px;
    min-width: 115px;
    margin-right: 0px;
    margin-top: -70px;
    margin-bottom: 10px;
  }

  .homepage-about-info p {
    font-size: 16px;
    line-height: 24px;
  }

  .homepage-about-info strong {
    font-size: 18px;
  }

  .home-pro-new-card-img {
    height: auto;
    max-height: 250px;
  }

  section.box-content {
    padding: 10px 0px 0px 0px;
  }

  header.new-header .new-header-items:nth-child(2) {
    order: 2;
  }

  .new-menu a {
    font-size: 19px;
    margin-right: 10px !important;
  }

  section.homepage-about {
    margin-bottom: 15px;
  }

  .new-logo {
    top: 0px;
  }

  ul.side-submenu {
    left: 0;
    border-radius: 0;
    width: 100%;
    border: none;
    z-index: 999;
    top: 50px;
  }
}

.footer-column-list {
  columns: 2;
}

.footer-nodal-officer {
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.footer-nodal-officer p {
  margin: 0px;
}

.new-header-2 {
  background: #e1e1e1;
  width: 100%;
  padding: 8px 5%;
  z-index: 10000 !important;
}

.top-page-title {
  float: right;
  font-size: 18px;
  color: #fff;
  font-weight: 600;
  padding-top: 5px;
}

.top-page-title a {
  color: #000;
  text-decoration: none;
  font-weight: 600;
  font-size: 16px;
  float: left;
  padding: 5px 7px;
}

.hamburger {
  position: relative;
  width: 40px;
  height: 34px;
  display: inline-flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;
  padding: 6px;
  margin-left: -4px;
  margin-top: -4px;
}

.hamburger:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 20px;

  transform: rotate(0deg);
  transition: all 0.4s cubic-bezier(0.54, -0.1, 0.57, 0.57);
}

.hamburger .hamburger-input {
  position: absolute;
  opacity: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  cursor: pointer;
}

.hamburger .hamburger-line {
  width: 100%;
  background: #000;
  height: 4px;
  display: block;
  border-radius: 6px;
  transition: transform 0.4s cubic-bezier(0.54, -0.81, 0.57, 0.57);
  position: relative;
  -webkit-transition: transform 0.4s cubic-bezier(0.54, -0.81, 0.57, 0.57);
  -moz-transition: transform 0.4s cubic-bezier(0.54, -0.81, 0.57, 0.57);
  -ms-transition: transform 0.4s cubic-bezier(0.54, -0.81, 0.57, 0.57);
  -o-transition: transform 0.4s cubic-bezier(0.54, -0.81, 0.57, 0.57);
}

.hamburger .hamburger-line.first,
.hamburger .hamburger-line.third {
  width: 100%;
}

.hamburger .hamburger-line.third {
  margin-left: 0%;
  transform-origin: left;
}

.menu-button-wrapper {
  position: relative;
  display: inline-block;
  background: #e1e1e1;
  width: 40px;
  height: auto;
  padding-top: 0px;
  padding: 0px;
  height: 32px;
}

.menu-button-wrapper .item-list {
  position: absolute;
  top: 71px;
  transform: translateX(-0%) scale(0);
  transform-origin: center;
  transition: all 0.4s cubic-bezier(-0.54, -0.24, 0.57, 0.57);
  color: #fff;
  width: 100%;
  padding: 15px 0;
  text-align: left;
  border-radius: 0px;
  font-weight: 300;
  opacity: 0;
  user-select: none;
  min-width: 1720px;
  /* -webkit-border-radius: ;
  -moz-border-radius: ;
  -ms-border-radius: ;
  -o-border-radius: ; */
  -webkit-transform: translateX(-0%) scale(0);
  -moz-transform: translateX(-0%) scale(0);
  -ms-transform: translateX(-0%) scale(0);
  -o-transform: translateX(-0%) scale(0);
  -webkit-transition: all 0.4s cubic-bezier(-0.54, -0.24, 0.57, 0.57);
  -moz-transition: all 0.4s cubic-bezier(-0.54, -0.24, 0.57, 0.57);
  -ms-transition: all 0.4s cubic-bezier(-0.54, -0.24, 0.57, 0.57);
  -o-transition: all 0.4s cubic-bezier(-0.54, -0.24, 0.57, 0.57);
}

.container {
  position: relative;
}

.menu-button-wrapper .menu-button {
  position: absolute;
  width: 39px;
  height: 39px;
  left: 0;
  z-index: 2;
  opacity: 0;
  cursor: pointer;
}

.main-menu.active .item-list {
  transform: translateX(-94%) scale(1);
  margin-top: -28px;
  z-index: 9999;
  border-radius: 0px;
  opacity: 1;
  user-select: auto;
  -webkit-transform: translateX(-0%) scale(1);
  -moz-transform: translateX(-0%) scale(1);
  -ms-transform: translateX(-0%) scale(1);
  -o-transform: translateX(-0%) scale(1);
  /* -webkit-border-radius: ;
  -moz-border-radius: ;
  -ms-border-radius: ;
  -o-border-radius: ; */
  background: hsla(0, 0%, 95%, 1);
  background: linear-gradient(180deg,
      hsla(0, 0%, 95%, 1) 0%,
      hsla(0, 0%, 99%, 1) 49%,
      hsla(0, 0%, 86%, 1) 100%);
  background: -moz-linear-gradient(180deg,
      hsla(0, 0%, 95%, 1) 0%,
      hsla(0, 0%, 99%, 1) 49%,
      hsla(0, 0%, 86%, 1) 100%);
  background: -webkit-linear-gradient(180deg,
      hsla(0, 0%, 95%, 1) 0%,
      hsla(0, 0%, 99%, 1) 49%,
      hsla(0, 0%, 86%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#F2F2F2", endColorstr="#FCFCFC", GradientType=1);

  background-repeat: repeat;
  color: #000;
  background: url(../images/../images/bg-nav.png);
}

.main-menu.active .icon-wrapper .hamburger-line.second {
  transform: rotate(-45deg);
}

.main-menu.active .icon-wrapper .hamburger-line.first {
  -webkit-transform: translate(2px, 8px) rotate(-135deg);
  transform: translate(0px, 9px) rotate(-135deg);
}

.main-menu.active .icon-wrapper .hamburger-line.third {
  transform: translate(24px, 0px) rotate(-135deg);
  -webkit-transform: translate(24px, 0px) rotate(-135deg);
  -moz-transform: translate(24px, -0px) rotate(-135deg);
  -ms-transform: translate(24px, -0px) rotate(-135deg);
  -o-transform: translate(24px, -0px) rotate(-135deg);
}

.main-menu.active .icon-wrapper .hamburger:before {
  transform: rotate(45deg);
}

.main-menu {
  margin-top: 0px;
  float: left;
}

.new-menu-icon {
  display: none;
}

.main-m1 {
  padding: 0px;
  width: 33px;
  float: left;
}

.navbar-nav-1 li {
  float: left;
  list-style: none;
  padding: 0px 30px 10px;
  width: 20%;
}

.navbar-nav-1 li a {
  color: #fff;
  padding: 0px;
  position: relative;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.navbar-nav-1 li a:hover {
  position: relative;
  display: inline-block;
  -webkit-transition: -webkit-transform 0.4s;
  transition: transform 0.4s;
}

.dropdown-menu1 {
  width: 100%;
  padding: 0px;
  margin: 0px 0px;
  float: left;
}

.dropdown-menu1 li {
  width: 100%;
  padding: 0px;
  margin: 0px;
  float: left;
}

.dropdown-menu1 .dropdown-menu1 {
  background: #9bc6e6;
  float: left;
  padding: 10px;
  border-radius: 10px;
}

.toggle-menu1 {
  font-weight: 900;
  font-size: 15px;
}

.navbar-nav-1 li {
  min-height: 260px;
  border-right: 1px solid #dbdbdb;
}

.dropdown-item1.item-a1.dropdown-toggle {
  display: none;
}

.dropdown-toggle::after {
  display: none;
}

.carousel-home .carousel-indicators li {
  position: relative;
  flex: 0 1 auto;
  width: 17px;
  height: 17px;
  margin-right: 3px;
  margin-left: 3px;
  border-radius: 50%;
  text-indent: -999px;
  background-color: #094f82;
}

.carousel-home .carousel-indicators li.active {
  background-color: #fa8620;
}

.carousel-home .carousel-caption {
  float: right;
  right: 0;
  background: url(../images/bg.png) repeat;
  width: 580px;
  text-align: left;
  padding: 30px;
  font-size: 30px;
  left: inherit;
  right: 5%;
  border-left: 5px solid #fa8620;
}

.home_programmes {
  background: url(../images/banner-2.png) no-repeat;
  background-size: 100% 100%;
}

.view-more-btn-1 {
  background: #094f82;
  color: #fff;
  padding: 6px 15px;
}

.view-more-btn-1:hover {
  background: #094f82;
  color: #fff;
}

.view-more-btn {
  text-align: right;
  padding: 0px 0px 15px;
}

.cm-profile-blog {
  background: url(../images/vg.png) no-repeat;
  background-size: 100% 100%;
  padding: 70px 0px;
}

.profile-btn {
  background: #fa8620;
  color: #fff;
  margin-top: -140px;
  -webkit-clip-path: polygon(0 0, 100% 0, 30px 100%, 0 100%);
  clip-path: polygon(0 0, 156px 0, 121px 69px, 0 44px);
  /* width: 160px; */
  padding-right: 150px;
  border-radius: 0px;
  font-size: 16px;
}

.cm-interact-blog {
  background: url(../images/cm-banner.png) no-repeat;
  background-size: 100% auto;
  padding: 100px 0px;
}

.interact2 {
  float: right;
  border-right: 1px solid #094f82;
  padding-right: 150px;
}

.interact3 {
  padding-left: 150px;
}

.cm-interact-blog .line {
  text-align: center;
  margin-bottom: 20px;
}

.cm-interact-blog .page-title {
  font-size: 35px;
  color: #fff;
  font-weight: 700;
}

.interact4 {
  padding-right: 0px;
}

.new-header-items {
  position: absolute;
  top: 82px;
}

.center-footer {
  text-align: center;
}

.page-title {
  font-size: 35px;
  color: #094f82;
  font-weight: 700;
  text-align: center;
  margin-bottom: 10px;
}

.line {
  text-align: center;
  margin-bottom: 50px;
}

.websitelinks {
  background: url(../images/banner-2.png) no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: auto;
  padding: 50px 0px;
}

.page-footer-link-1 {
  background: #fff;
  text-align: center;
  font-weight: 700;
  padding: 10px;
}

.page-footer-link-2 {
  padding: 40px 8px;
  border: 1px solid #b9b9b9;
  font-size: 16px;
}

.page-footer-link-2 a {
  color: #000;
  text-decoration: none;
}

.cm-governance {
  padding: 50px 0px;
}

.governance-blog-1 {
  background: #f6f3e0 url(../images/bg4.jpg) no-repeat;
  background-size: 80% auto;
  width: 100%;
  height: auto;
  padding: 40px;
  background-position: bottom;
  background-position: right bottom;
}

.governance-blog-3 {
  background: url(../images/bg5.png) no-repeat;
  background-size: 100% auto;
  width: 100%;
  height: auto;
  padding: 40px;
  background-position: bottom;
  color: #fff;
  position: relative;
}

.governance-blog-2 {
  padding: 0px;
  position: relative;
  padding: 40px;
}

.cm-photo {
  background: url(../images/cm-bg.png) no-repeat;
  background-size: 100% 100%;
  text-align: center;
}

.cm1-photo2 {
  width: 160px;
  margin-top: 50px;
  border-radius: 10px;
}

.cm-text {
  width: 80%;
  margin: 15px auto;
  text-align: center;
  border-radius: 15px;
  border: 1px solid #000;
  padding: 15px;
  position: relative;
}

.cm-text-2 {
  font-weight: 800;
  color: #094f82;
  font-size: 20px;
  padding-bottom: 5px;
}

.cm-text-4 {
  text-align: center;
}

.formercm-text-2 {
  width: 100%;
  margin: 0px auto;
  padding-top: 10px;
}

.formercm-text-2 img {
  width: 100%;
}

.formercm-text-1 {
  width: 100%;
  margin: 0px auto;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0px 15px 15px;
  line-height: 21px;
}

.formercm-text-1 span {
  font-size: 16px;
  font-weight: 400;
}

.former-cm {
  background: #cccccc;
  padding: 15px 0px 20px;
  margin-top: 15px;
  position: relative;
  width: 100%;
  float: left;
  border-radius: 15px;
  border: 4px solid #fff;
}

.governance-blog-1 h3,
.formercm-text-new {
  color: #094f82;
  font-weight: 700;
  padding-bottom: 0px;
  margin-bottom: 10px;
  font-size: 24px;
}

.governance-blog-3 h3 {
  color: #094f82;
  font-weight: 700;
  padding-bottom: 0px;
  margin-bottom: 10px;
  font-size: 24px;
}

.text-2 {
  line-height: 25px;
  padding-bottom: 30px;
  color: #000;
  text-align: center;
}

.governance-cm1 {
  width: 290px;
  right: 0;
  position: absolute;
  bottom: 0;
}

.navbar-nav-1 li h5 {
  color: #000;
  font-weight: 600;
  font-size: 18px;
  padding: 10px 0px;
  margin: 0px;
}

.navbar-nav-1 li {
  line-height: 22px;
}

.navbar-nav-1 li a:hover {
  color: #000;
}

.navbar-inner {
  width: 100%;
  float: left;
  padding: 0px;
  margin: 0px;
}

.navbar-inner li {
  width: 100%;
  border: 0px;
  height: auto;
  min-height: auto;
  float: left;
  color: #000;
  font-weight: 500;
  padding: 2px 0px;
}

.text-nav {
  font-weight: 500;
}

.navbar-inner li a {
  color: #000;
  font-family: "Poppins", sans-serif;
}

.governance-blog-1 {
  width: 35%;
  float: left;
}

.governance-blog-2 {
  width: 30%;
  float: left;
  background: url(../images/banner-2.png) no-repeat;
  background-size: auto 100%;
  background-position: 5%;
}

.governance-blog-3 {
  width: 35%;
  float: left;
}

.cm-governance .row {
  margin: 0px;
}

.view-more-btn {
  padding-bottom: 50px;
  padding-right: 15px;
}

.breadcrumbs-page-img {
  min-height: 114px;
}

.breadcrumbs-page-img h1 {
  display: none;
}

.about-yajona-text h2 {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 0px;
  padding: 0px 15px 15px;
}

.about-yajona-text .card-title {
  background-color: #123d6a;
  color: #fff;
  padding: 10px;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
}

.breadcrumb {
  border-radius: 0px;
  background-color: #123d6a;
  width: 100%;
  padding: 12px 5%;
}

.close-in {
  background: #000 !important;
  width: 50px;
  height: 50px;
  line-height: 0px;
  border-radius: 50%;
  color: #fff;
  margin-top: -40px !important;
  margin-right: -40px !important;
  opacity: 1;
  border: 2px solid #fff !important;
  font-weight: 500;
}

.close-in:hover {
  background: #000 !important;
  opacity: 1 !important;
  color: #fff !important;
  border: 2px solid #fff !important;
}

.view-more-btn-1 {
  color: #fff !important;
}

.footer_social {
  float: right;
}

.footer_social .fa {
  font-size: 18px;
}

.footer_social .fa:hover,
.footer_social a:hover {
  color: #fff;
}

.svg-img-icon img {
  width: 18px;
}

.svg-img-icon path {
  fill: #fff;
}

.cm-event ul {
  padding: 0px;
  margin: 0px -15px;
}

.cm-event ul li {
  list-style: none;
  float: left;
  width: 50%;
  padding: 15px;
}

.cm-event ul li img {
  width: 100%;
}

.cm-event ul li img {
  width: 100%;
  border-radius: 15px;
  border: 4px solid #fff;
}

.cm1-event {
  width: 100%;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.blog-social {
  background: #d4d4d4;
}

.social-m {
  float: right;
}

.social-m a {
  font-size: 14px;
  float: right;
  margin-left: 5px;
}

.social-m a {
  font-size: 14px;
  float: right;
  margin-left: 5px;
  background: #2794e1;
  color: #fff !important;
  padding: 7px;
  height: 31px;
}

.twitter-icon path {
  fill: #fff;
}

.social-m img {
  width: 25px;
  height: 25px;
  text-decoration: none;
}

.social-m .svg-img-icon svg {
  width: 15px;
}

.svg-img-icon path {
  fill: #000;
}

.cm1-img2 {
  text-align: center;
  margin-bottom: 5px;
}

.cm1-img {
  width: 90px;
  margin: 0px auto;
  border: 4px solid #fff;
  border-radius: 100%;
}

.inner-page-banner {
  width: 100%;
  left: 0;
}

.new-header-2 .fa-video-camera {
  margin-right: 4px;
}

.icon-head {
  margin-top: 3px;
  position: relative;
  float: right;
  padding: 0px 7px;
}

.fa {
  font-weight: 900;
}

.carousel-caption-in {
  left: 5%;
  right: 5%;
  text-align: left;
}

.sub-banner-text {
  max-width: 730px;
  /* font-size: 16px; */
  color: #000;
  padding: 20px 0px;
}

.sub-banner-btn a {
  font-size: 16px;
  color: #fa8620;
  margin-right: 15px;
  border-bottom: 1px solid #fa8620;
}

.sub-banner-btn a:hover {
  color: #000;
  border-bottom: 1px solid #000;
}

.header-banner h2 {
  color: #094f82;
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 0px;
}

.banner-title .sub-title {
  font-size: 20px;
  color: #000;
  /* padding-bottom: 30px; */
}

.stay {
  margin-right: -2%;
  background: #fff;
  color: #000;
}

.stay {
  margin-left: -6%;
  background: #fff;
  color: #000;
  position: relative;
  padding-left: 6%;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 30px;
  margin-bottom: 10px;
  padding-right: 30px;
  width: auto;
  max-width: max-content;
}

.stay h3 {
  color: #fa8620;
  font-size: 40px;
  padding-bottom: 0px;
  margin: 0px;
  font-weight: bold;
}

.stay-sub {
  font-size: 22px;
  font-weight: 500;
  color: #094f82;
}

.relief-block {
  padding: 15px 25px;
}

.relief-fund .container .row {
  margin: 0px !important;
}

.relief-3 {
  height: auto;
  float: left;
  padding: 0px 15px;
  background: #f1f1f1;
  border-right: 20px solid #fff;
}

.relief-4 {
  height: auto;
  float: left;
  padding: 0px 15px;
}

.relief-bloc1 {
  font-size: 28px;
  font-weight: 700;
  color: #fa8620;
  line-height: 33px;
}

.relief-bloc1 span {
  font-size: 16px;
  color: #094f82;
  font-weight: 500;
  text-transform: uppercase;
}

.relief-bloc2 {
  width: 50%;
  float: left;
  font-size: 24px;
  font-weight: 500;
}

.crl {
  clear: both;
}

.relief-bloc2 span {
  font-size: 30px;
  font-weight: 300;
}

.relief-4 {
  background: #f1f1f1;
  padding: 25px;
}

.donet h4 {
  color: #094f82;
  font-size: 24px;
  font-weight: 700;
}

.donet-block-1 {
  width: 21%;
  float: left;
}

.donet-block-2 {
  width: 72%;
  float: left;
  padding-left: 20px;
  font-size: 16px;
  line-height: 23px;
  font-weight: 600;
}

.relief-fund {
  width: 100%;
  padding-bottom: 50px;
}

.interact-3 {
  z-index: 1;
  position: relative;
}

.interact-2 {
  position: relative;
}

.interact-1 {
  font-size: 29px;
  color: #fa8620;
  font-weight: 700;
  position: relative;
  padding-bottom: 11px;
}

.interact-b1 {
  float: left;
}

.thoughts {
  background: url(../images/con1.png) no-repeat;
  background-size: 100% 100%;
  float: left;
  width: 300px;
  height: 110px;
  padding-left: 50px;
  line-height: 28px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding-top: 23px;
  border-radius: 0px 15px 15px 0px;
}

.interact-3 {
  background: url(../images/con2.png) no-repeat;
  background-size: 100% 100%;
  float: left;
  width: 330px;
  height: 110px;
  padding-left: 50px;
  line-height: 28px;
  color: #fff;
  font-size: 20px;
  font-weight: 600;
  padding-top: 22px;
  border-radius: 0px 15px 15px 0px;
  margin-top: 15px;
  margin-left: 50px;
}

.interact-3 img {
  float: left;
}

.interact-box {
  max-width: 570px;
}

.top_news .container-fluid ul {
  max-width: 1170px;
  margin: 0px auto;
}

.new-header-2 .new-header-items {
  /* background: url(../images/logo-bg.png) no-repeat; */
  background-size: 100% 100%;
  padding: 0px;
  width: 293px;
}

.inner-page-banner img {
  object-fit: cover;
  max-width: max-content;
}

.cm-photo-blog {
  overflow: hidden;
}

/*Zoom In*/
.cm-photo-blog a img {
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.cm-photo-blog a:hover img {
  -webkit-transform: scale(1.11);
  transform: scale(1.11);
}

.icon-head.icon-facebook {
  color: blue;
}

.icon-head.icon-insta {
  color: #ee3459;
}

.icon-head.icon-you {
  color: red;
}

.fix-hader {
  position: fixed;
  width: 100%;
  z-index: 9;
  top: 0;
}

.program-img-bx a {
  display: block;
  width: 81%;
}

.sub-banner-text span {
  padding-bottom: 10px;
  line-height: 24px;
}

.home_programmes.home_programmes2 {
  background: #fff;
}

.newClass .new-header-items {
  display: none;
}

.control-bpx-2 input {
  float: left;
  width: 80%;
}

.control-bpx-2 .fa-refresh {
  float: right;
  line-height: 41px;
  cursor: pointer;
}

.press-icon2 {
  width: 100px !important;
  height: 100px !important;
  margin-top: 24px;
}

.profile-table table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.profile-table td,
.profile-table th {
  border: 1px solid #d3d3d3;
  text-align: left;
  padding: 8px;
}

.profile-table tr:nth-child(even) {
  background-color: #f4f4f4;
}

.profile-tbody {
  background: #000;
  color: #fff;
}

.profile-tbody td,
.profile-tbody th {
  border: 1px solid #fff;
  text-align: left;
  padding: 8px;
}

.profile-tbody {
  border: 1px solid #d3d3d3;
}

.HeaderStyle th {
  background-color: #000 !important;
}

.card.uploader-gallery-list-items .embed-responsive::before {
  padding-top: 56% !important;
}

@media (max-width: 1810px) {
  .menu-button-wrapper .item-list {
    min-width: 1700px;
  }

  .page-footer-link-2 {
    padding: 31px 15px;
    font-size: 16px;
    min-height: 108px;
  }
}

@media (max-width: 1650px) {
  .menu-button-wrapper .item-list {
    min-width: 1600px;
  }

  .cm-interact-blog {
    background-size: auto 100%;
    padding: 60px 0px;
  }

  .stay {
    margin-top: 10px;
    /* margin-bottom: 10px; */
  }

  .banner-sub-img img {
    width: 200px;
  }

  .header-banner h2 {
    font-size: 24px;
  }

  .banner-title .sub-title {
    font-size: 16px;
    padding-bottom: 20px;
  }

  .stay h3 {
    font-size: 38px;
  }

  .stay-sub {
    font-size: 18px;
    padding-bottom: 10px;
  }

  .cta {
    margin-top: 20px !important;
    max-width: 600px !important;
  }

  .cta strong {
    font-size: 10px !important;
  }

  .cta h3 {
    font-size: 12px !important;
  }

  .cta h3 span {
    font-size: 12px !important;
  }

  .cta-link {
    font-size: 12px !important;
  }

  .cta img {
    height: 50px !important;
  }

  body .text-2 iframe {
    width: 100% !important;
  }

  .relief-bloc2 {
    font-size: 24px;
    font-weight: 500;
  }

  .relief-bloc2 span {
    font-size: 16px;
  }

  .relief-bloc1 {
    font-size: 22px;
    line-height: 25px;
  }

  .donet h4 {
    font-size: 17px;
  }

  .donet-block-2 {
    font-size: 14px;
    line-height: 18px;
  }

  .cm-interact-blog {
    background-position: 25%;
  }

  .governance-blog-2 h3,
  .governance-blog-1 h3,
  .formercm-text-new,
  .interact-1 {
    font-size: 18px;
  }

  .page-title {
    font-size: 24px;
  }

  #about,
  #programmes,
  #testimonials,
  #call-to-action,
  #portfolio {
    padding: 40px 0px 0px;
  }
}

@media (max-width: 1550px) {
  .menu-button-wrapper .item-list {
    min-width: 1500px;
  }
}

@media (max-width: 1450px) {
  .menu-button-wrapper .item-list {
    min-width: 1305px;
  }
}

@media (max-width: 1390px) {
  .menu-button-wrapper .item-list {
    min-width: 1200px;
  }

  .stay {
    margin-top: 15px;
    margin-bottom: 10px;
  }

  .sub-banner-text {
    padding: 10px 0px;
    font-size: 14px;
  }

  .banner-title .sub-title {
    padding-bottom: 10px;
    font-size: 15px;
  }

  .stay h3 {
    font-size: 22px;
  }

  .stay-sub {
    font-size: 16px;
  }

  .cta {
    margin-top: 20px !important;
    max-width: 550px !important;
  }

  .cta strong {
    font-size: 9px !important;
  }

  .cta h3 {
    font-size: 10px !important;
  }

  .cta h3 span {
    font-size: 10px !important;
  }

  .cta-link {
    font-size: 11px !important;
  }

  .cta img {
    height: 47px !important;
  }

  .carousel-caption {
    padding-bottom: 0px;
  }
}

@media (max-width: 1250px) {
  .menu-button-wrapper .item-list {
    min-width: 1100px;
  }
}

@media (max-width: 1150px) {
  .menu-button-wrapper .item-list {
    min-width: 1000px;
  }
}

@media (max-width: 1050px) {
  .menu-button-wrapper .item-list {
    min-width: 900px;
  }

  .stay {
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .banner-sub-img img {
    width: 130px;
  }
}

@media (max-width: 1024px) {
  .menu-button-wrapper .item-list {
    min-width: 300px;
  }

  .navbar-nav-1 li {
    line-height: 22px;
    width: 100%;
    height: auto;
    min-height: auto;
    padding: 0px;
  }

  .carousel-caption {
    padding-bottom: 0px;
    position: relative;
    padding: 60px 0px 0px;
    float: left;
  }

  .cmo-photo-title {
    padding-left: 13px;
    padding-top: 8px;
    font-size: 14px;
  }

  .cmo-photo-title img {
    margin-right: 5px;
    width: 18px;
  }

  .programmes-photo img {
    width: auto !important;
    height: auto !important;
    border-radius: 10px;
  }
}

@media (max-width: 767px) {
  .new-logo {
    margin: 0px;
    padding: 0px;
    margin-top: 0px;
    float: left;
  }

  .top-page-title a {
    color: #000;
    text-decoration: none;
    font-weight: 600;
    font-size: 13px;
    float: left;
    padding: 0px 3px;
  }

  .top-page-title {
    font-size: 15px;
    font-weight: 600;
    padding-top: 8px;
  }

  .page-title {
    font-size: 24px;
  }

  .governance-blog-1,
  .governance-blog-3 {
    padding: 20px;
  }

  .governance-blog-1 {
    width: 100%;
    float: left;
  }

  .governance-blog-2 {
    width: 100%;
    float: left;
  }

  .governance-blog-3 {
    width: 100%;
    float: left;
    padding-bottom: 0px;
  }

  .governance-cm1 {
    position: relative;
  }

  .footer-links ul {
    display: block;
  }

  .cm-interact-blog .page-title {
    font-size: 25px;
  }
}

@media (max-width: 768px) {
  .relief-3 {
    border-right: 0px solid #fff;
  }

  .interact-b1 img {
    width: 100px;
  }

  .thoughts {
    width: 256px;
    height: 90px;
    padding-left: 50px;
    line-height: 23px;
    font-size: 16px;
    padding-top: 20px;
  }

  .interact-3 {
    width: 256px;
    height: 90px;
    padding-left: 50px;
    line-height: 23px;
    font-size: 16px;
    padding-top: 20px;
  }

  .interact-3 img {
    width: 44px;
  }

  .sub-banner-text {
    max-width: 94%;
  }

  .main.main-menu .navbar-nav-1 {
    padding-left: 13px;
    height: 380px;
    overflow: hidden;
    padding-bottom: 0px;
    overflow: scroll;
  }
}

@media (max-width: 460px) {
  .programmes-photo {
    width: 100%;
    float: left;
  }

  .line {
    text-align: center;
    margin-bottom: 20px;
  }

  .programmes-content {
    width: 100%;
  }

  .governance-blog-2 {
    min-height: 620px;
  }

  #programmes {
    padding-top: 30px;
  }

  .page-footer-link-1 {
    margin-top: 10px;
  }

  .interact2 {
    float: none;
    border-right: 0;
    padding-right: 0px;
    text-align: center;
  }

  .interact3 {
    padding-left: 0px;
    text-align: center;
  }

  .interact-box {
    max-width: 100%;
    padding-left: 17%;
  }

  .interact-b1 {
    display: none;
  }

  .thoughts {
    width: 243px;
    height: 90px;
    padding-left: 42px;
    line-height: 23px;
    font-size: 16px;
    padding-top: 20px;
  }

  .interact-3 {
    width: 229px;
    margin-left: 20px;
    padding-left: 30px;
    line-height: 23px;
    font-size: 16px;
    padding-top: 20px;
  }

  .relief-fund {
    padding-bottom: 0xp;
  }
}

.modal-dialog {
  height: -webkit-fill-available !important;
}

.profil-image {
  position: relative;
  text-align: center;
  margin: -17px;
}

.profil-image img {
  width: 100%;
  height: auto;
}

.profil-text-overlay {
  position: absolute;
  top: 45%;
  left: 30%;
  transform: translate(-50%, -50%);
}

.profil-text-overlay h1,
.profil-text-overlay p {
  margin: 0;
}

.profil-text-overlay h1 {
  color: #094f82;
  font-weight: 700;
  font-family: "Poppins";
}

.profil-text-overlay p {
  font-size: 20px;
  font-family: "Poppins";
}

@media (max-width: 768px) {
  .profil-text-overlay h1 {
    font-size: 1.5rem;
  }

  .profil-text-overlay p {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .profil-text-overlay h1 {
    font-size: 20px;
  }

  .profil-text-overlay p {
    font-size: 10px;
  }
}

@media (max-width: 572px) {
  .profil-text-overlay h1 {
    font-size: 15px;
  }

  .profil-text-overlay p {
    font-size: 10px;
  }
}

@media (max-width: 410px) {
  .profil-text-overlay h1 {
    font-size: 10px;
  }

  .profil-text-overlay p {
    font-size: 8px;
  }
}

.profil-image img {
  width: 100%;
}

.profil-text {
  padding: 50px 0px 30px;
}

.life-text {
  border-bottom: 1px solid #b7b7b7;
}

.life-text h2 {
  color: #094f82;
  font-family: "Poppins";
  font-weight: 700;
}

.life-text2 p {
  margin-top: 20px;
  font-size: 20px;
  font-family: "Poppins";
  font-weight: 400;
}

@media (max-width: 1310px) {
  .cmo-photo-title {
    padding-left: 13px;
    padding-top: 8px;
    font-size: 20px;
  }
}

.cta {
  display: flex;
  justify-content: flex-start;
  background: rgba(255, 255, 255, 0.3);
  font-family: "Poppins", sans-serif;
  width: 100%;
  max-width: 720px;
  margin-left: -6%;
  padding-left: 5%;
  margin-top: 40px;
}

.rr-logo {
  margin: 10px;
}

.cta strong {
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 500;
  color: #000;
}

.cta h3 {
  font-size: 16px;
  margin-top: 40px;
  font-weight: 700;
  color: #000;
}

.cta h3 span {
  font-size: 14px;
  font-weight: normal;
  font-weight: 400;
}

.cta-link {
  border: none;
  outline: none;
  padding-bottom: 5px;
  font-size: 16px;
  color: #183a68;
  text-transform: capitalize;
  font-weight: 700;
  margin-top: 50px;
  margin-left: 30px;
  text-decoration: underline;
}

.cta-link:hover {
  color: #fa8620;
  text-decoration: underline;
}

.rotate {
  animation: rotation 8s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

#gt {
  color: orange;
  animation: blinking-usingCSSBlink 1s linear infinite;
}

@keyframes blinking-usingCSSBlink {
  50% {
    opacity: 0;
  }
}

#gt {
  color: #183a68;
  animation: blinking-usingCSSBlinkColor 1s linear infinite;
}

@keyframes blinking-usingCSSBlinkColor {
  20% {
    color: red;
  }

  80% {
    color: #02b056;
  }
}

.form-inline {
  display: flex !important;
  align-items: flex-end !important;
  flex-flow: row wrap !important;
}

.search-btn {
  margin-bottom: 7px;
}

a {
  text-decoration: none !important;
}

.modal-backdrop {
  z-index: 8 !important;
}

@media (max-width: 460px) {

  /* .new-header-2 .new-header-items {
    display: none;
  } */
  .itu {
    width: 100px !important;
  }

  .new-logo .img-3 {
    width: 29px;
  }

  .new-logo .logo-text .logo-top-text {
    font-size: 9px;
  }

  .logo-bottom-text {
    font-size: 8px;
  }
}

@media (max-width: 420px) {
  .live-stream {
    display: none;
  }

  .cta {
    margin-top: 20px !important;
    max-width: 100% !important;
  }

  .cta strong {
    font-size: 7px !important;
  }

  .cta h3 {
    font-size: 8px !important;
  }

  .cta h3 span {
    font-size: 8px !important;
  }

  .cta-link {
    font-size: 9px !important;
  }

  .cta img {
    height: 32px !important;
  }
}

@media (max-width: 1235px) {
  .banner-title h2 {
    font-size: 15px;
  }

  .sub-title {
    font-size: 15px;
  }

  .sub-banner-text {
    font-size: 13px;
  }

  .sub-banner-btn a {
    font-size: 12px;
  }
}

@media (max-width: 1186px) {
  .banner-title h2 {
    font-size: 12px !important;
  }

  .sub-title {
    font-size: 10px !important;
  }

  .sub-banner-text {
    font-size: 11px !important;
  }

  .sub-banner-btn a {
    font-size: 9px !important;
  }

  .stay {
    margin-bottom: 0px !important;
  }

  .cta {
    margin-top: 0px !important;
  }
}

.main-image {
  max-height: 500px;
  object-fit: contain;
}


@media screen and (max-width: 1024px) {

  .stats-counter,
  .stats-counter-left,
  .stats-counter-right {
    flex-direction: column;
    width: 100%;
  }
}

/*  */

/* .marquee {
  margin: 0 auto;
  width: 100%;
  height: 785px;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  position: relative;
}

.marquee:before,
.marquee:after {
  position: absolute;
  top: 0;
  width: 50px;
  height: 30px;
  content: "";
  z-index: 1;
}

.marquee:before {
  left: 0;
}

.marquee:after {
  right: 0;
} */

/*  */

.marquee__content {
  animation: marquee 7s linear infinite forwards;
}

.marquee__content:hover {
  animation-play-state: paused;
}

@keyframes marquee__content {
  20% {
    transform: translateY(50%);
  }
}

@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

.mini-loading i {
  font-size: 50px;
  color: #156280;
}

.mini-loading {
  font-size: 20px;
}